import * as React from 'react';
import getHref from '../../util/getHref';
import jwt from 'jwt-decode';
import tokenDidNotExpire from '../../util/tokenDidNotExpire';
import { useNavigate } from 'react-router-dom';
import { Prefs } from '../../App';
import { login } from '../../service';

import { PageLayout } from '../page-layout';
import { usePlaying } from '../../context/playing';
import { EmphasisTitle } from '../common/EmphasisTitle';
import { InputForm } from '../Input-form';
import { Paragraph } from '../common/Paragraph';
import Button from '../common/Button';
import { SocialLogin } from '../common/social-login';

enum LoginErrorType {
  USER,
  DEFAULT,
}

export const Login: React.FC = () => {
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [loading, setIsLoading] = React.useState<boolean>(false);
  const [loginError, setLoginError] = React.useState<boolean>(false);
  const [errorType, setErrorType] = React.useState<LoginErrorType>(LoginErrorType.DEFAULT);
  const navigate = useNavigate();
  const { origin } = usePlaying();

  if (localStorage.getItem('token')) {
    localStorage.clear();
  }

  const handleLoginButtonPressed = async () => {
    try {
      setIsLoading(true);
      localStorage.clear();
      const jwt_token = await login(email, password);
      const jwt_values: Prefs = jwt(jwt_token.data.token);

      localStorage.setItem('token', jwt_token.data.token);

      if (jwt_values.avatar && jwt_values.avatar !== '') {
        localStorage.setItem('avatar', jwt_values.avatar);
        localStorage.setItem('email', jwt_values.email);
        localStorage.setItem('avatarName', jwt_values.avatarName || '');
        window.dispatchEvent(new Event('login'));
      } else {
        return navigate(getHref('choose-avatar'));
      }

      if (jwt_values.city && jwt_values.city !== '') {
        localStorage.setItem('city', jwt_values.city);
        localStorage.setItem('game', jwt_values.game || '');
      } else {
        return navigate(getHref('choose-city'));
      }
      setLoginError(false);

      return navigate(getHref(''));
    } catch (error: any) {
      console.error({ error });

      if (error.response?.data?.message === 'User not found') setErrorType(LoginErrorType.USER);
      if (error.response?.data?.message === 'Invalid user or password')
        setErrorType(LoginErrorType.DEFAULT);

      setLoginError(true);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (!loginError) return;
    setTimeout(() => {
      setLoginError(() => {
        setErrorType(LoginErrorType.DEFAULT);
        return false;
      });
    }, 4000);
  }, [loginError]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleLoginButtonPressed();
  };

  return (
    <PageLayout className="mx-0 px-10 py-0 font-[Raleway] md:py-8">
      <div className="mx-0 flex h-full w-full items-center justify-center">
        <div className="flex w-full flex-col items-center justify-center sm:w-[45rem]">
          <img
            src={
              origin.includes('neoenergia')
                ? '/img/rockinrio/logo-rock-neo.png'
                : '/svg/cidadeazullogo.svg'
            }
            alt="logo"
            className="h-72 w-72 object-contain"
          />
          <EmphasisTitle className="mb-8 mt-4 text-secondary">Entre com sua conta:</EmphasisTitle>
          <form
            onSubmit={handleSubmit}
            className="flex w-full flex-col items-center justify-center gap-6"
          >
            <InputForm
              className="px-8 text-start"
              name="email"
              label="Email"
              placeholder="Email ou Nickname"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
            <InputForm
              className="px-8 text-start"
              name="password"
              label="Senha"
              placeholder="Senha"
              value={password}
              type="password"
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
            {loginError && (
              <Paragraph className="mt-6 text-center font-semibold text-[#DE3838]">
                {errorType === LoginErrorType.USER &&
                  'Olá! Este email/nickname não está cadastrado!'}
                Olá! O email/nickname ou senha é inválido. Tente novamente.
              </Paragraph>
            )}
            <Button
              type="submit"
              className="w-full"
              value={!loading ? 'Entrar' : 'Carregando...'}
              inactive={loading}
            />
          </form>
          <a
            href={getHref('recover-password')}
            className="mt-6 text-2xl font-bold text-secondary md:text-3xl"
          >
            Esqueceu sua senha? Clique Aqui.
          </a>
          <SocialLogin setLoginError={setLoginError} hasAccount={false} />
        </div>
      </div>
    </PageLayout>
  );
};
