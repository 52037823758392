import { useLayoutEffect, useRef, useState } from 'react';

import { PageLayout } from '../../components/page-layout';
import { getPublishedVideos } from '../../service';
import { Card } from '../../components/card';
import { VideoRender } from '../../components/video-render';
import { gradeClassifier } from '../../util/grade-classifier';
import { Filters, FilterComponent } from '../../components/filter-component';
import { ratings } from '../../components/filter-component/options';
import { companiesByCity } from '../../util/verifyCityBelongsClient';
import { firstCharacterToUppercase } from '../../util/first-character-touppercase';
import { applyFilters } from '../../components/filter-component/apply-filters';
import PageMargin from '../../components/page-margin/page-margin';
import { EmphasisTitle } from '../../components/common/EmphasisTitle';
import { useLoading } from '../../hooks/use-loading';

export const NewPublicGallery = () => {
  const [filters, setFilters] = useState<Filters>({
    city: '',
    company: '',
    mission: '',
    rating: '',
    world: '',
    class: '',
    game: '',
  });

  const [videos, setVideos] = useState<VideoData[]>([]);
  const videoRenderParentRef = useRef<HTMLDivElement>(null);
  const { setIsLoading } = useLoading();

  useLayoutEffect(() => {
    setIsLoading(true);
    const fetchVideos = async () => {
      const response = await getPublishedVideos();
      setVideos(response);
      setIsLoading(false);
    };
    fetchVideos();
  }, []);

  return (
    <PageLayout pageTitle="Galeria Geral">
      <PageMargin className="mt-10">
        <EmphasisTitle className="font-semibold sm:leading-relaxed">
          Bem-vinda(o) à Galeria Geral. Aqui você encontra os vídeos aprovados de todas as jogadoras
          ou jogadores. Utilize os filtros abaixo para filtrar os vídeos por cidade, por mundo, por
          missão e os melhores.
        </EmphasisTitle>
        <FilterComponent
          filters={filters}
          setFilters={setFilters}
          enableRatingFilter
          enableCompanyFilter
          enableCityFilter
        />
        <div className="grid h-full grid-cols-1 place-items-center gap-8 md:grid-cols-2 md:place-items-start lg:grid-cols-3">
          {!videos.length ? (
            <h1 className="m-auto text-4xl text-neutral-400">
              Não há videos publicados no momento
            </h1>
          ) : (
            videos
              .filter((video) => applyFilters(video, filters))
              .map((video) => {
                return (
                  <Card className="w-full items-start">
                    <div className="mb-4 flex w-full justify-center" ref={videoRenderParentRef}>
                      <VideoRender
                        parentRef={videoRenderParentRef}
                        className="flex w-full justify-center"
                        video={video?.link_video}
                        service={video?.service}
                      />
                    </div>
                    <div className="flex items-center gap-4">
                      <p className="text-2xl font-extrabold">Avatar:</p>
                      <p className="text-2xl font-semibold leading-snug">
                        {video?.user?.name_avatar}
                      </p>
                    </div>
                    <div className="flex items-center gap-4">
                      <p className="text-2xl font-extrabold">Empresa:</p>
                      <p className="text-2xl font-semibold leading-snug">
                        {firstCharacterToUppercase(
                          companiesByCity[video.city.toLowerCase().trim()],
                        )}
                      </p>
                    </div>
                    <div className="flex items-center gap-4">
                      <p className="text-2xl font-extrabold">Cidade:</p>
                      <p className="text-2xl font-semibold leading-snug">
                        {firstCharacterToUppercase(video?.city)}
                      </p>
                    </div>
                    <div className="flex items-center gap-4">
                      <p className="text-2xl font-extrabold">Mundo:</p>
                      <p className="text-2xl font-semibold leading-snug">{video?.world}</p>
                    </div>
                    <div className="flex items-center gap-4">
                      <p className="text-2xl font-extrabold">Jogo:</p>
                      <p className="text-2xl font-semibold leading-snug">{video?.game}</p>
                    </div>
                    <div className="flex items-center gap-4">
                      <p className="text-2xl font-extrabold">Missão:</p>
                      <p className="text-2xl font-semibold leading-snug">{video?.mission}</p>
                    </div>
                    <div className="flex items-center gap-4">
                      <p className="text-2xl font-extrabold">Pontuação total:</p>
                      <p className="text-2xl font-semibold leading-snug">{video?.total_score}</p>
                    </div>
                    <div className="flex items-center gap-4">
                      <p className="text-2xl font-extrabold">Avaliação:</p>
                      <p className="text-2xl font-semibold leading-snug">
                        {ratings.find(
                          (rating) => rating.value === gradeClassifier(video?.total_score!),
                        )?.label || 'Não cumpriu! :('}
                      </p>
                    </div>
                    <div className="flex items-center gap-4">
                      <p className="text-2xl font-extrabold">Código de turma:</p>
                      <p className="text-2xl font-semibold leading-snug">{video?.class_code}</p>
                    </div>
                  </Card>
                );
              })
          )}
        </div>
      </PageMargin>
    </PageLayout>
  );
};
