import React, { useCallback, useEffect, useMemo } from 'react';
import { getProgress, getUserVideosForClient } from '../../service';
import { MissionCard, World } from './components/mission-card';
import { WorldMissionList } from './components/world-mission-list';
import { PageLayout } from '../../components/page-layout';
import { useQuery } from '@tanstack/react-query';
import { useLoading } from '../../hooks/use-loading';
import PageMargin from '../../components/page-margin/page-margin';
import Button from '../../components/common/Button';
import { Paragraph } from '../../components/common/Paragraph';

const WorldsObj: Record<string, Record<string, World>> = {
  'O Mundo da Água': {
    'Economia de Água': {
      name: 'Mundo da Água',
      image: '/img/journal/economiadeagua.png',
    },
    'Reúso de Água': {
      name: 'Mundo da Água',
      image: '/img/journal/reusodeagua.png',
    },
    rainWater: {
      name: 'Mundo da Água',
      image: '/img/journal/aguadechuva.png',
    },
  },
  'O Mundo do Saneamento': {
    'A história do Saneamento': {
      name: 'Mundo do Saneamento',
      image: '/img/journal/historiadosaneamento.png',
    },
    'Saneamento e Saúde': {
      name: 'Mundo do Saneamento',
      image: '/img/journal/saneamentoesaude.png',
    },
    // treatmentAndInovations: {
    //     name: "Mundo do Saneamento",
    //     image: "/img/journal/tratamentoeinovacoes.png",
    // },
  },
  'O Mundo dos Resíduos': {
    'Resíduos Orgânicos': {
      name: 'Mundo dos Resíduos',
      image: '/img/journal/residuosorganicos.png',
    },
    'Resíduos Recicláveis': {
      name: 'Mundo dos Resíduos',
      image: '/img/journal/residuosreciclaveis.png',
    },
    // other: {
    //     name: "Mundo dos Resíduos",
    //     image: "/img/journal/outrosresiduos.png",
    // },
  },
};

type TGameCategory = 'water' | 'sanitation' | 'waste' | '';

export const NewVideoGallery = () => {
  const [filter, setFilter] = React.useState<TGameCategory>('');
  const gamesCategory = React.useMemo(() => {
    return {
      water: 'O Mundo da Água',
      sanitation: 'O Mundo do Saneamento',
      waste: 'O Mundo dos Resíduos',
    };
  }, []);

  /* 
        Publicar vídeo: POST /dev/video/save-link 
            link_video: "required|string",
            city: "required|string",
            world: "required|string",
            mission: "required|integer",

        Listar videos: GET /dev/video/list-user-videos-links

        */

  const enabledGames = useMemo(
    () => ['Reúso de Água', 'A história do Saneamento', 'Resíduos Orgânicos'],
    [],
  );

  const gameUrlParams: Record<(typeof enabledGames)[number], string> = useMemo(() => {
    return {
      'Reúso de Água': 'water-reuse',
      'A história do Saneamento': 'sanitation-history',
      'Resíduos Orgânicos': 'recyclable-waste',
    };
  }, []);

  const { setIsLoading } = useLoading();

  const progress = useQuery({
    queryKey: ['progress'],
    queryFn: getProgress,
    refetchOnWindowFocus: false,
  });
  const videos = useQuery({
    queryKey: ['videos'],
    queryFn: getUserVideosForClient,
    refetchOnWindowFocus: false,
  });

  const isRefetching = progress.isRefetching || videos.isRefetching;
  const refetch = useCallback(() => {
    progress.refetch();
    videos.refetch();
  }, [progress, videos]);

  useEffect(() => {
    if (progress.isLoading || videos.isLoading || isRefetching) setIsLoading(true);
    else setIsLoading(false);
  }, [isRefetching, progress.isLoading, setIsLoading, videos.isLoading]);

  const allWorlds = useMemo(
    () =>
      progress.data?.worlds?.map((mission1) => {
        const { world, games, tokens, mission, bubbles, totalWorldScore } = mission1;
        return {
          games,
          world,
          tokens,
          mission,
          bubbles,
          totalWorldScore,
        };
      }) || [],
    [progress.data],
  );

  const renderCards = useMemo(() => {
    if (!progress.data) return;
    if (!allWorlds.length) return;

    const activeGames = [] as JSX.Element[];
    const inactiveGames = [] as JSX.Element[];

    const allCards = allWorlds.map((worlds) => {
      const { world, games, totalWorldScore } = worlds;
      // const orderedGames = world !== 'O Mundo dos Resíduos' ? games : [...games].reverse();

      if (filter === '' || gamesCategory[filter] === world) {
        const missionCards = games?.map((game, index: number) => {
          const video = videos.data?.find(
            (v) => v.game === game?.gameName && world.toLowerCase().includes(v.world.toLowerCase()),
          );

          const actualGame = (
            <MissionCard
              gameUrlParam={gameUrlParams[game.gameName]}
              key={game?.gameName}
              refetch={refetch}
              world={WorldsObj[world][game?.gameName] || {}}
              game={game?.gameName}
              disabled={!enabledGames.includes(game.gameName)}
              mission={{
                number: index,
                name: game?.gameName,
                world: WorldsObj[world][game?.gameName] || {},
                completed: game?.finishedGame,
                video,
                videoStatus: video?.status,
                evaluations: video?.evaluations,
                virtualPoints: totalWorldScore,
                videoPoints: video?.score ?? 0,
                finalPoints: game?.totalGameScore + (video?.score ?? 0),
              }}
              tokens={game?.tokens || []}
            />
          );
          if (enabledGames.includes(game.gameName)) {
            activeGames.push(actualGame);
          } else {
            inactiveGames.push(actualGame);
          }
          return actualGame;
        });
        return <WorldMissionList key={world}>{missionCards}</WorldMissionList>;
      }
      return null;
    });
    return (
      <>
        <div className="hidden sm:flex">
          <div className="grid grid-cols-1 place-items-start gap-8 xl:grid-cols-3">{allCards}</div>
        </div>
        <div className="flex w-full flex-col items-center justify-center gap-8 sm:hidden">
          {activeGames}
          {inactiveGames}
        </div>
      </>
    );
  }, [
    allWorlds,
    enabledGames,
    filter,
    gamesCategory,
    progress.data,
    refetch,
    videos.data,
    gameUrlParams,
  ]);

  return (
    <PageLayout pageTitle="Galeria do Jogador" className="mx-0 p-10">
      <PageMargin className="items-center gap-0">
        <div className="mb-6 flex flex-col items-start sm:flex-row sm:items-start">
          <div className="flex sm:mr-4">
            <img
              className="z-10 h-[12rem] lg:mt-16 lg:h-[20rem]"
              src={'/img/avatars/' + localStorage.getItem('avatar') + '.png'}
              alt="Avatar do jogador"
            />
            <div className="flex flex-col items-center justify-center gap-4 lg:gap-8">
              <img
                className="-ml-8 h-[7rem] w-full lg:mt-14 lg:h-48"
                src={`/svg/${localStorage.getItem('city')}.svg`}
                alt="Cidade atual do jogador"
              />
              <div className="-ml-24 mb-4 flex h-12 w-72 flex-col justify-center rounded-2xl bg-neutral-800 px-2 sm:mb-8 lg:h-16 lg:w-72">
                <h1 className="ml-24 overflow-hidden text-ellipsis whitespace-nowrap text-3xl font-semibold text-neutral-50">
                  {localStorage.getItem('avatarName')}
                </h1>
              </div>
            </div>
          </div>
          <div className="mt-4 flex h-full w-full items-end justify-end sm:ml-4 sm:mt-4 sm:flex-1 sm:pb-6 md:mt-0 lg:mt-0">
            <Paragraph className="font-semibold leading-relaxed md:text-2xl lg:text-4xl lg:leading-relaxed">
              Jogadora ou jogador, bem-vinda(o) à Galeria do Jogador. Aqui você acompanha o status
              dos seus desafios e insere os vídeos de missões cumpridas.
            </Paragraph>
          </div>
        </div>
        <div className="mb-10 flex h-10 w-full items-center rounded-3xl bg-[#C4E4F5] px-12 py-10 text-2xl sm:text-3xl lg:h-14 xl:mb-12">
          <p className="mr-4 font-black">Pontuação Total:</p>
          <p className="font-semibold">
            {(progress.data ? progress.data.totalScore : 0) +
              (videos.data
                ? videos.data.reduce((acc, v) => acc + (v?.score ?? 0), 0) ?? 0
                : 0)}{' '}
            {' pontos'}
          </p>
        </div>
        <div className="mb-10 flex w-full flex-col items-center justify-center gap-2 sm:flex-row lg:hidden lg:gap-8">
          <Button
            className="w-5/6 overflow-hidden text-ellipsis whitespace-nowrap text-xl sm:px-2 lg:text-3xl"
            active={filter === 'water'}
            value="O Mundo da Água"
            onClick={() => setFilter((oldState) => (oldState === 'water' ? '' : 'water'))}
          />
          <Button
            className="w-5/6 overflow-hidden text-ellipsis whitespace-nowrap text-xl sm:px-2 lg:text-3xl"
            active={filter === 'sanitation'}
            value="O Mundo do Saneamento"
            onClick={() => setFilter((oldState) => (oldState === 'sanitation' ? '' : 'sanitation'))}
          />
          <Button
            className="w-5/6 overflow-hidden text-ellipsis whitespace-nowrap text-xl sm:px-2 lg:text-3xl"
            active={filter === 'waste'}
            value="O Mundo dos Resíduos"
            onClick={() => setFilter((oldState) => (oldState === 'waste' ? '' : 'waste'))}
          />
        </div>
        {renderCards}
      </PageMargin>
    </PageLayout>
  );
};
