import React from 'react';
import { Card } from '../../../../../components/card';
import { EvaluationsList } from '../../../../../components/evaluations-list';
import { Token } from '../../../../new-video-gallery/components/mission-card/components/token';
import { deleteUserByTeacher, resetUserPasswordByTeacher } from '../../../../../service';
import DeleteConfirm from '../../../../../components/common/DeleteConfirm';
import { FaLock, FaTrash } from 'react-icons/fa';
import { capitalizeAfterSpace } from '../../../../../util/capitalizeString';
import { progressObject } from '../../../../../components/filter-component/options';

export const StudentCardProgress = ({ student, classCode, students }: any) => {
  const enabledGames = React.useMemo(() => {
    return ['Reúso de Água', 'A história do Saneamento', 'Resíduos Orgânicos'];
  }, []);

  const resetUserPassword = async () => {
    const response = await resetUserPasswordByTeacher(student?.userId, classCode);
    if (response?.status === 200) {
      return true;
    }
  };

  const deleteUser = async () => {
    const response = await deleteUserByTeacher(student?.userId);
    if (response?.status === 200) {
      students.refetch();
      return true;
    }
  };

  return (
    <div>
      <div className="mb-12 flex w-full flex-col gap-4">
        <div className="flex flex-row items-center gap-20">
          <div className="flex flex-row gap-4">
            <p className="text-3xl font-extrabold">Nome do aluno:</p>
            <p className="text-3xl font-semibold">
              {capitalizeAfterSpace(student?.userName) || '-'}
            </p>
          </div>
          <div className="flex flex-row gap-4">
            <DeleteConfirm
              onClick={resetUserPassword}
              buttonName={'Resetar senha'}
              messageText={`Tem certeza que deseja resetar a senha do usuário? O aluno deverá executar o mesmo fluxo do primeiro acesso inserindo nome completo e código de turma`}
              buttonProps={'h-14 bg-[#009C9D] text-2xl'}
              icon={<FaLock className="mx-auto mb-4 h-14 w-14 text-gray-200" aria-hidden="true" />}
            />
            <DeleteConfirm
              onClick={deleteUser}
              buttonProps={'h-14 bg-zinc-400 text-2xl'}
              buttonName={'Apagar Aluno'}
              messageText={`Tem certeza que deseja apagar o aluno?`}
              icon={<FaTrash className="mx-auto mb-4 h-14 w-14 text-gray-200" />}
            />
          </div>
        </div>
      </div>
      <div className="grid w-full grid-cols-3 gap-4">
        {student.worlds &&
          Object.entries(student.worlds).map(([worldName, worldData]: [string, any], idxWorld) => (
            <div className="flex flex-col gap-6" key={idxWorld}>
              <div className="flex w-full flex-col items-start">
                {worldData &&
                  Object.entries(worldData.games).map(
                    ([gameName, gameData]: [string, any], gameIdx) => {
                      if (!enabledGames.includes(gameName)) return null;
                      return (
                        <Card className="mb-4 gap-8">
                          <div className="flex w-full flex-col gap-2" key={gameIdx}>
                            <div className="flex gap-2">
                              <p className="text-2xl font-extrabold">Mundo:</p>
                              <p className="text-2xl font-semibold">{worldName}</p>
                            </div>
                            <div className="flex gap-2">
                              <p className="text-2xl font-extrabold">Jogo:</p>
                              <p className="text-2xl font-semibold">{gameName}</p>
                            </div>
                            <div className="flex gap-2">
                              <p className="text-2xl font-extrabold">Progressão do jogo virtual:</p>
                            </div>
                            <div className="my-4 grid w-full grid-cols-3 gap-4">
                              {gameData &&
                                Object.entries(gameData.weeks).map(
                                  ([_, weekData]: [string, any], weekIdx) => (
                                    <div className="flex flex-col gap-2" key={weekIdx}>
                                      <Token value={weekData.token1} />
                                      <Token value={weekData.token2} />
                                    </div>
                                  ),
                                )}
                            </div>
                            <div className="mt-4 flex gap-2">
                              <p className="text-2xl font-extrabold">Pontuação virtual:</p>
                              <p className="text-2xl font-semibold">{gameData.totalGameScore}</p>
                            </div>
                            <div className="flex gap-2">
                              <p className="text-2xl font-extrabold">Missão video:</p>
                              <p className="text-2xl font-semibold">
                                {gameData.videoMissionName ||
                                  progressObject[worldName][gameName].mission ||
                                  '-'}
                              </p>
                            </div>
                            <div className="flex gap-2">
                              <p className="text-2xl font-extrabold">Status do video:</p>
                              <p className="text-2xl font-semibold">{gameData.videoStatus}</p>
                            </div>
                            <div className="my-6 flex w-full flex-col">
                              <EvaluationsList
                                evaluations={{
                                  content: gameData.approvedContent,
                                  duration: gameData.approvedDuration,
                                  originality: gameData.approvedOriginality,
                                }}
                              />
                            </div>
                            <div className="flex gap-2">
                              <p className="text-2xl font-extrabold">Pontuação do video:</p>
                              <p className="text-2xl font-semibold">{gameData.totalVideoScore}</p>
                            </div>
                            <div className="flex gap-2">
                              <p className="text-2xl font-extrabold">Pontuação total:</p>
                              <p className="text-2xl font-semibold">{worldData.worldScore}</p>
                            </div>
                          </div>
                        </Card>
                      );
                    },
                  )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
