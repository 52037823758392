import axios from 'axios';

const stage: any = process.env.REACT_APP_STAGE || 'dev';

const stageURLs: any = {
  prod: 'https://7nmqb7s83c.execute-api.sa-east-1.amazonaws.com/prod',
  dev: 'https://zot49fmfkk.execute-api.sa-east-1.amazonaws.com/dev',
  // dev: 'http://localhost:4000/dev',
};

const URL = stageURLs[stage];

if (stage === 'dev') {
  console.info(`Stage: ${process.env.REACT_APP_STAGE}`);
  console.info(`Backend URL: ${URL}`);
}

const axiosInstance = axios.create({
  baseURL: URL,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      originalRequest._retry = true;
      try {
        localStorage.clear();
        window.location.href = '/login';
      } catch (err) {
        console.log('Erro ao atualizar token', err);
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  },
);

export const login = async (email: string, password: string) => {
  return await axiosInstance.post(
    `/user/login`,
    { email, password },
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  );
};

export const loginAdmin = async (email: string, password: string) => {
  return await axiosInstance.post(
    `/user/login-admin`,
    { email, password },
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  );
};

export const loginTeacher = async (email: string, password: string) => {
  return await axiosInstance.post(
    `/teacher/login`,
    { email, password },
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  );
};

export const refreshAccessToken = async () => {
  const token = localStorage.getItem('token');
  return await axiosInstance.post(
    `/user/refresh-token`,
    { token },
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  );
};

export const emailConfirmationTeacher = async (email: string, code: string) => {
  return await axiosInstance.post(
    `/teacher/email-confirmation`,
    { email, code },
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  );
};

export const register = async (email: string, password: string, origin: string) => {
  return await axiosInstance.post(
    `/user/register`,
    { email, password, origin },
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  );
};

export const registerStudent = async (name: string, code: string, origin: string) => {
  return await axiosInstance.post<RegisterUserByClassResponse>(
    `/user/auth-by-class`,
    { name, code, origin },
    { headers: { 'Content-Type': 'application/json' } },
  );
};

export const updateStudentUserPassword = async (
  name_avatar: string,
  password: string,
  validationToken: string,
) => {
  return await axiosInstance.post(
    `/user/update-username-password`,
    { name_avatar, password, validationToken },
    { headers: { 'Content-Type': 'application/json' } },
  );
};

export const resetUserPasswordByTeacher = async (userId: string, classCode: string) => {
  const token = localStorage.getItem('teacher-token');
  return await axiosInstance.post(
    `/teacher/reset-user-password`,
    { userId, classCode },
    { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } },
  );
};

export const deleteUserByTeacher = async (userId: string) => {
  const token = localStorage.getItem('teacher-token');
  return await axiosInstance.delete(`/teacher/delete-user`, {
    params: { userId },
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
};

export const recoverPassword = async (email: string, origin: string) => {
  return await axiosInstance.post(
    `/user/recover-user-password`,
    { email, origin },
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  );
};

export const recoverPasswordTeacher = async (email: string) => {
  return await axiosInstance.post(
    `/teacher/recover-password`,
    { email },
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  );
};

export const updateUserPassword = async (email: string, code: string, password: string) => {
  return await axiosInstance.post(
    `/user/change-user-password`,
    { email, code, password },
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  );
};

export const updateTeacherPassword = async (email: string, code: string, password: string) => {
  return await axiosInstance.post(
    `/teacher/change-password`,
    { email, code, password },
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  );
};

export const googleAuthentication = async (code: string, origin: string, type: string) => {
  return await axiosInstance.post(
    `/user/google-authentication`,
    { code, origin, type },
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  );
};

export const facebookAuthentication = async (user_id: string, access_token: string) => {
  return await axiosInstance.post(
    `/user/facebook-authentication`,
    { user_id, access_token },
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  );
};

export const tikTokAuthenticationauth = async (code: string | null) => {
  return await axiosInstance.post(
    `/user/tiktok-authentication`,
    { code },
    { headers: { 'Content-Type': 'application/json' } },
  );
};

export const tikTokOauth = async () => {
  return await axiosInstance.get(`/user/tiktok-oauth`, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
};

export const createProfile = async (
  email: string,
  code: string,
  name: string,
  name_avatar: string,
  avatar: string,
  teacher_class?: string,
) => {
  return await axiosInstance.post(
    `/user/create-profile`,
    { email, code, name, name_avatar, avatar, teacher_class },
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  );
};

export const createProfileAuth = async (
  name: string,
  name_avatar: string,
  avatar: string,
  teacher_class?: string,
) => {
  const token = localStorage.getItem('token');

  return await axiosInstance.post(
    `/user/create-profile-auth`,
    { name, name_avatar, avatar, teacher_class },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const setCity = async (city: string, game: string) => {
  const token = localStorage.getItem('token');

  return await axiosInstance.post(
    `/user/set-city`,
    { city, game },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const getProgress = async (): Promise<Progress> => {
  type Response = {
    data: Progress;
  };

  const token = localStorage.getItem('token');

  const response = await axiosInstance.get<Response>(`/user/get-progress`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data.data;
};

export const getSettings = async () => {
  const token = localStorage.getItem('token');

  return await axiosInstance.get(`/user/get-info`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getPublishedVideos = async () => {
  const response = await axiosInstance.get<VideoData[]>(`/video/list-published-videos`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  return response.data;
};

export const getUnpublishedVideos = async () => {
  const token = localStorage.getItem('token');

  const response = await axiosInstance.get<VideoData[]>(`/video/list-unpublished-videos`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const getUserVideosForClient = async (): Promise<VideoData[]> => {
  const token = localStorage.getItem('token');

  const response = await axiosInstance.get<VideoData[]>(`/video/list-user-videos-links`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const saveVideoLink = async (
  link_video: string,
  city: string,
  world: string,
  mission: string,
  game: string,
  virtualScore: number,
) => {
  const token = localStorage.getItem('token');
  console.log(virtualScore);
  return await axiosInstance.post<VideoData>(
    `/video/save-link`,
    { link_video, city, world, mission, game, virtualScore },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const deleteVideoLink = async (id: string, email: string) => {
  const token = localStorage.getItem('token');

  return await axiosInstance.post(
    `/video/delete-link`,
    { id, email },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const publishVideo = async (
  id: string,
  email: string,
  content: number,
  originality: number,
  duration: number,
) => {
  const token = localStorage.getItem('token');

  return await axiosInstance.post(
    `/video/publish-video`,
    { id, email, content, originality, duration },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const reproveVideo = async (id: string, email: string) => {
  const token = localStorage.getItem('token');

  return await axiosInstance.post(
    `/video/reprove-video`,
    { id, email },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const updateVideoEvaluation = async (
  email: string,
  id: string,
  content: number,
  duration: number,
  originality: number,
) => {
  const token = localStorage.getItem('token');

  return await axiosInstance.post(
    `/video/update-evaluation`,
    { email, id, content, duration, originality },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const getTeacherProfile = async () => {
  const token = localStorage.getItem('teacher-token');

  return await axiosInstance.get<TeacherProfile>(`/teacher/get-info`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    },
  });
};

export type ClassResponse = {
  id: string;
  code: string;
  year: string;
  created_at: string;
  teacher_email: string;
  type: string;
  PK: string;
  SK: string;
}[];
export const getTeacherListClass = async () => {
  const token = localStorage.getItem('teacher-token');

  return await axiosInstance.get<ClassResponse>(`/teacher/list-class`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    },
  });
};

export type ExtendedProgress = {
  name: string;
  email: string;
  progress: Progress;
  videos: VideoData[];
};
export const getTeacherProgressUsersByClass = async (classId: any): Promise<ExtendedProgress[]> => {
  type Response = {
    data: ExtendedProgress[];
  };
  const token = localStorage.getItem('teacher-token');
  const response = await axiosInstance.get<Response>(
    `teacher/get-progress-class?classId=${classId}`,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data.data;
};

export const removeTeacherClass = async (class_code: string, teacher_email: string) => {
  const token = localStorage.getItem('teacher-token');

  return await axiosInstance.post(
    `/teacher/remove-class`,
    { class_code, teacher_email },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const registerTeacher = async (
  name: string,
  email: string,
  city: string,
  school: string,
  password: string,
) => {
  return await axiosInstance.post(
    `/teacher/register`,
    { name, email, city, school, password },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
};

export const updateTeacher = async (
  name: string,
  email: string,
  city: string,
  school: string,
  password: string | boolean,
) => {
  const token = localStorage.getItem('teacher-token');

  const body: any = {
    name,
    email,
    city,
    school,
    password,
  };

  if (!password) delete body.password;

  return await axiosInstance.post(`/teacher/update`, body, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const registerClass = async (year: string, type: string) => {
  const token = localStorage.getItem('teacher-token');

  return await axiosInstance.post(
    `/teacher/register-class`,
    { year, type },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const registerUser = async (name: string, classId: number) => {
  const token = localStorage.getItem('teacher-token');

  return await axiosInstance.post(
    `/teacher/register-user`,
    { name, classId },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const getPerformance = async ({
  period,
  company,
  city,
  world,
  game,
}: PlayerPerformanceParams) => {
  const token = localStorage.getItem('token');
  const queryParams = new URLSearchParams();

  if (period && period?.start && period?.end) {
    queryParams.append('startDate', period?.start);
    queryParams.append('endDate', period?.end);
  }

  company && queryParams.append('company', company.toLowerCase());
  city && queryParams.append('city', city.toLowerCase());
  world && queryParams.append('world', world);
  game && queryParams.append('game', game);

  const responsePlayerPerformance = await axiosInstance.get<PlayerPerformanceResponse>(
    `/reports/get-users`,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
      },
      params: queryParams,
    },
  );

  const responseTeacherPerformance = await axiosInstance.get<TeacherPerformanceResponse>(
    `/reports/get-teachers`,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return {
    playerPerformance: responsePlayerPerformance.data.kpis,
    teacherPerformance: responseTeacherPerformance.data.kpis,
  };
};
