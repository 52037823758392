import * as React from 'react';
import { toast, ToastContainer } from 'react-toastify';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { registerTeacher } from '../../../service';
import { InputForm } from '../../Input-form';
import { PageLayout } from '../../page-layout';
import { usePlaying } from '../../../context/playing';
import { SocialLogin } from '../../common/social-login';
import { TeacherInfo } from './TeacherInfo';
import { EmphasisTitle } from '../../common/EmphasisTitle';
import Button from '../../common/Button';
import { SelectForm } from '../../select-form';
import { allCities } from '../../../util/consts';
import { Paragraph } from '../../common/Paragraph';

interface FormValues {
  name: string;
  email: string;
  city: string;
  school: string;
  password: string;
  confirmPassword: string;
}

const validationSchema = Yup.object({
  name: Yup.string()
    .required('Campo obrigatório')
    .min(3, 'O nome deve ter pelo menos 3 caracteres'),
  email: Yup.string().required('Campo obrigatório').email('Email inválido'),
  city: Yup.string()
    .required('Campo obrigatório')
    .min(2, 'O nome da cidade deve ter pelo menos 2 caracteres'),
  school: Yup.string()
    .required('Campo obrigatório')
    .min(4, 'O nome da escola deve ter pelo menos 4 caracteres'),
  password: Yup.string()
    .required('Campo obrigatório')
    .min(8, 'A senha deve ter no mínimo 8 caracteres'),
  confirmPassword: Yup.string()
    .required('Campo obrigatório')
    .oneOf([Yup.ref('password')], 'As senhas não coincidem'),
});

const Register = () => {
  const [loading, setIsLoading] = React.useState<boolean>(false);
  const [registerSuccess, setRegisterSuccess] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const { origin } = usePlaying();

  if (localStorage.getItem('token')) {
    localStorage.clear();
  }

  const handleCreateAccount: any = async (values: FormValues) => {
    try {
      setIsLoading(true);
      await registerTeacher(values.name, values.email, values.city, values.school, values.password);
      formik.resetForm();
      setRegisterSuccess(true);
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      console.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      city: '',
      school: '',
      password: '',
      confirmPassword: '',
    },
    onSubmit: handleCreateAccount,
    validationSchema,
  });

  React.useEffect(() => {
    if (!errorMessage) return;
    setTimeout(() => {
      setErrorMessage('');
    }, 4000);
  }, [errorMessage]);

  return (
    <>
      <TeacherInfo />
      <PageLayout className="mx-0 px-10 py-0 font-[Raleway] md:py-8">
        <div className="mx-0 flex h-full w-full items-center justify-center">
          <div className="flex w-full flex-col items-center justify-center md:w-[45rem] xl:w-[65rem]">
            <img
              src={
                origin.includes('neoenergia')
                  ? '/img/rockinrio/logo-rock-neo.png'
                  : '/svg/cidadeazullogo.svg'
              }
              alt="logo"
              className="h-72 w-72 object-contain"
            />
            <EmphasisTitle className="mb-8 mt-4 text-secondary">
              Crie sua conta de professor(a):
            </EmphasisTitle>
            <form className="w-full items-center justify-center" onSubmit={formik.handleSubmit}>
              <div className="mb-8 grid w-full grid-cols-1 flex-col items-center justify-center gap-6 md:grid-cols-2 lg:ml-8">
                <InputForm
                  className="px-8 text-start"
                  name="name"
                  label="Nome"
                  placeholder="Nome"
                  formik={formik}
                  value={formik.values?.name}
                  error={formik.errors?.name}
                />
                <InputForm
                  className="px-8 text-start"
                  name="email"
                  label="Email"
                  placeholder="Email"
                  formik={formik}
                  value={formik.values?.email}
                  error={formik.errors?.email}
                />
                <SelectForm
                  className="px-8 text-start"
                  name="city"
                  placeholder="Cidade"
                  formik={formik}
                  value={formik.values?.city}
                  error={formik.errors?.city}
                  options={allCities.map((city) => ({
                    value: city,
                    label: city,
                  }))}
                />
                <InputForm
                  className="px-8 text-start"
                  name="school"
                  label="Escola"
                  placeholder="Escola"
                  formik={formik}
                  value={formik.values?.school}
                  error={formik.errors?.school}
                />
                <InputForm
                  className="px-8 text-start"
                  name="password"
                  label="Senha"
                  placeholder="Senha"
                  type="password"
                  formik={formik}
                  value={formik.values?.password}
                  error={formik.errors?.password}
                />
                <InputForm
                  className="px-8 text-start"
                  name="confirmPassword"
                  label="Confirmar Senha"
                  placeholder="Confirmar senha"
                  formik={formik}
                  value={formik.values?.confirmPassword}
                  error={formik.errors?.confirmPassword}
                  type="password"
                />
              </div>
              {registerSuccess && (
                <div className="w-full">
                  <Paragraph className="my-6 text-center font-semibold text-[#DE3838] lg:ml-10">
                    Conta criada com sucesso! Enviamos um email para o email cadastrado, siga as
                    instruções para ativar a conta.
                  </Paragraph>
                </div>
              )}
              {errorMessage && (
                <div className="w-full">
                  <Paragraph className="my-6 text-center font-semibold text-[#DE3838] lg:ml-10">
                    {errorMessage}
                  </Paragraph>
                </div>
              )}
              <div className="flex w-full items-center justify-center">
                <Button
                  className="w-full sm:w-6/12"
                  type="submit"
                  value={!loading ? 'Entrar' : 'Carregando...'}
                  inactive={loading}
                />
              </div>
            </form>
            <SocialLogin
              setLoginError={() => {
                console.log('login error');
              }}
              hasAccount
              hasTiktok={false}
              authType="TEACHER"
            />
          </div>
          <ToastContainer stacked style={{ fontSize: '14px' }} toastStyle={{ padding: '20px' }} />
        </div>
      </PageLayout>
    </>
  );
};

export default Register;
