import React, { useState } from 'react';

type PaginatedListProps = {
  title?: string;
  renderItems: () => JSX.Element[];
  itemsPerPage?: number;
};

const PaginatedList: React.FC<PaginatedListProps> = ({ title, renderItems, itemsPerPage = 10 }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const items = renderItems();
  const paginatedItems = items?.slice(startIndex, endIndex);

  const handleNext = () => {
    if (endIndex < items.length) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevious = () => {
    if (startIndex > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <div className="flex w-full flex-col justify-between">
      <h2 className="my-10 text-4xl font-bold">{title}</h2>
      <ul className="space-y-2">{paginatedItems}</ul>
      {items.length > itemsPerPage && (
        <div className="flex w-full items-center justify-center">
          <div className="mt-4 flex w-2/12 gap-24">
            <button
              className="disabled:cursor-not-allowed"
              onClick={handlePrevious}
              disabled={startIndex === 0}
            >
              <img
                className="h-14 w-14"
                src="/img/icons/previous-button.png"
                alt="Previous Button"
              />
            </button>
            <button
              className="disabled:cursor-not-allowed"
              onClick={handleNext}
              disabled={endIndex >= items.length}
            >
              <img className="h-14 w-14" src="/img/icons/next-button.png" alt="Next Button" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaginatedList;
