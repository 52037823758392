import { useState } from 'react';
import { PageLayout } from '../../components/page-layout';
import { Filters, FilterComponent } from '../../components/filter-component';

import { getPublishedVideos, getUnpublishedVideos } from '../../service';
import { useQuery } from '@tanstack/react-query';
import { UnpublishedVideo } from './components/unpublished-card';
import { applyFilters } from '../../components/filter-component/apply-filters';
import PageMargin from '../../components/page-margin/page-margin';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/common/Button';
import { EmphasisTitle } from '../../components/common/EmphasisTitle';

export const AdminPanel = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<Filters>({});
  const unpublishedVideos = useQuery({
    queryKey: ['unpublished-videos'],
    queryFn: getUnpublishedVideos,
    refetchOnWindowFocus: false,
  });
  const publishedVideos = useQuery({
    queryKey: ['published-videos'],
    queryFn: getPublishedVideos,
    refetchOnWindowFocus: false,
  });

  const isRefetching = unpublishedVideos.isFetching || publishedVideos.isFetching;
  const refetch = () => {
    unpublishedVideos.refetch();
    publishedVideos.refetch();
  };

  return (
    <PageLayout pageTitle="Painel Adm" marginFromTitle>
      <PageMargin>
        <div className="flex flex-col items-center justify-center lg:hidden">
          <h1 className="mt-16 text-center text-4xl leading-relaxed">
            Esta página está disponível apenas para Desktop
          </h1>
        </div>
        <div className="hidden flex-col gap-20 lg:flex">
          <EmphasisTitle className="font-semibold sm:leading-relaxed">
            Bem-vinda(o) ao Painel Adm. Aqui você classifica os vídeos de todas as jogadoras ou
            jogadores. Utilize os filtros abaixo para filtrar os vídeos por cidade, por mundo e por
            missão.
          </EmphasisTitle>
          <FilterComponent
            filters={filters}
            setFilters={setFilters}
            enableCompanyFilter
            enableCityFilter
          />
          <div className="flex h-full flex-row flex-wrap justify-center gap-8 sm:justify-start">
            {!isRefetching &&
              unpublishedVideos.data &&
              unpublishedVideos.data
                .concat(publishedVideos.data ?? [])
                .filter((video) => applyFilters(video, filters))
                .map((video) => (
                  <UnpublishedVideo
                    published={video.status === 'P'}
                    video={video}
                    refetch={refetch}
                  />
                ))}
          </div>
          <div className="flex w-full justify-end">
            <Button
              value="Relatório de performance"
              onClick={() => navigate('/performance-panel')}
            />
          </div>
        </div>
      </PageMargin>
    </PageLayout>
  );
};
