import React, { useState } from 'react';

type PaginatedStudentListProps = {
  renderItems: () => JSX.Element[];
  itemsPerPage?: number;
  currentPage?: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

const PaginatedStudentList: React.FC<PaginatedStudentListProps> = ({
  renderItems,
  itemsPerPage = 3,
  currentPage = 0,
  setCurrentPage,
}) => {
  const startIndex = React.useMemo(() => currentPage * itemsPerPage, [currentPage, itemsPerPage]);
  const endIndex = React.useMemo(() => startIndex + itemsPerPage, [startIndex, itemsPerPage]);
  const items = React.useMemo(() => renderItems(), [renderItems]);
  const paginatedItems = React.useMemo(() => {
    return items?.slice(startIndex, endIndex);
  }, [items, endIndex, startIndex]);

  const handleNext = () => {
    if (endIndex < items.length) {
      setCurrentPage((prevPage) => {
        if (prevPage >= items.length) return prevPage;
        return prevPage + 1;
      });
    }
  };

  const handlePrevious = () => {
    if (startIndex > 0) {
      setCurrentPage((prevPage) => {
        if (prevPage === 0) return 0;
        return prevPage - 1;
      });
    }
  };

  return (
    <div className="flex w-full flex-col justify-between">
      {paginatedItems}
      {items.length > itemsPerPage && (
        <div className="flex w-full items-center justify-center">
          <div className="mt-4 flex w-2/12 items-center justify-center gap-24">
            <button
              className="disabled:cursor-not-allowed"
              onClick={handlePrevious}
              disabled={startIndex === 0}
            >
              <img
                className="h-14 w-14"
                src="/img/icons/previous-button.png"
                alt="Previous Button"
              />
            </button>
            <button
              className="disabled:cursor-not-allowed"
              onClick={handleNext}
              disabled={endIndex >= items.length}
            >
              <img className="h-14 w-14" src="/img/icons/next-button.png" alt="Next Button" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaginatedStudentList;
