import { Token } from './components/token';
import { deleteVideoLink, saveVideoLink } from '../../../../service';
import React, { useEffect, useRef, useState } from 'react';
import videoStatus from '../../../../util/videoStatus';
import { useNavigate } from 'react-router-dom';
import { Input } from '../../../../components/input';
import { Card } from '../../../../components/card';
import { EvaluationsList } from '../../../../components/evaluations-list';
import { VideoRender } from '../../../../components/video-render';
import { progressObject } from '../../../../components/filter-component/options';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useLoading } from '../../../../hooks/use-loading';
import Button from '../../../../components/common/Button';
import { Paragraph } from '../../../../components/common/Paragraph';
import { EmphasisTitle } from '../../../../components/common/EmphasisTitle';

export interface World {
  name: string;
  image: string;
}

interface Mission {
  name: string;
  world?: World;
  video?: VideoData;
  completed?: boolean;
  evaluations?: VideoEvaluations;
  number: number;
  videoStatus?: string;
  virtualPoints?: number;
  videoPoints?: number;
  finalPoints?: number;
}

interface MissionCardProps {
  disabled?: boolean;
  gameUrlParam: string | undefined;
  game: string;
  world?: World;
  mission?: Mission;
  tokens?: number[];
  refetch: () => void;
}

export const MissionCard = ({
  world,
  mission,
  disabled,
  tokens = [],
  game,
  refetch,
  gameUrlParam,
}: MissionCardProps) => {
  const [videoLink, setVideoLink] = useState('');
  const videoRenderParentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();
  const gameData = React.useMemo(() => {
    const worldName = 'O ' + world?.name!;
    const gameData = progressObject[worldName][game];
    return gameData;
  }, [game, world]);

  const { mutate: saveVideo, isPending: isSaving } = useMutation({
    mutationFn: async () =>
      await saveVideoLink(
        videoLink,
        localStorage.getItem('city')!,
        'O ' + world?.name!,
        gameData.mission,
        game,
        mission?.virtualPoints!,
      ),
    onSuccess: () => {
      refetch();
      toast.success('Vídeo salvo com sucesso!');
    },
    onError: (error: string) => {
      toast.error('Erro ao salvar vídeo');
    },
  });
  const { mutate: deleteVideo, isPending: isDeleting } = useMutation({
    mutationFn: async () => await deleteVideoLink(mission?.video?.id!, mission?.video?.email!),
    onSuccess: () => {
      refetch();
      toast.success('Vídeo deletado com sucesso!');
    },
    onError: (error: string) => {
      toast.error('Erro ao deletar vídeo');
    },
  });

  useEffect(() => {
    if (isSaving || isDeleting) setIsLoading(true);
    else setIsLoading(false);
  }, [isSaving, isDeleting, setIsLoading]);

  const approvedContent = (mission?.video?.evaluations?.content || 0) >= 500;
  const approvedDuration = (mission?.video?.evaluations?.duration || 0) >= 500;
  const approvedOriginality = (mission?.video?.evaluations?.originality || 0) >= 1000;

  return (
    <Card disabled={disabled} className="gap-6">
      <EmphasisTitle className="text-center">{world?.name}</EmphasisTitle>
      <img className="w-60" src={world?.image} alt={world?.name} />
      <div className="grid w-full grid-cols-3 gap-4">
        <Token value={!disabled ? tokens[0] : 0} />
        <Token value={!disabled ? tokens[2] : 0} />
        <Token value={!disabled ? tokens[4] : 0} />
        <Token value={!disabled ? tokens[1] : 0} />
        <Token value={!disabled ? tokens[3] : 0} />
        <Token value={!disabled ? tokens[5] : 0} />
      </div>
      {!mission?.completed || tokens.length < 6 || disabled ? (
        <div className="flex w-full flex-col items-center justify-between gap-8">
          <Paragraph className="text-center font-semibold md:text-2xl">
            Você ainda não completou os desafios de {game}. Complete todos os desafios para poder
            enviar o vídeo da missão.
          </Paragraph>
          <Button onClick={() => navigate(`/game/${gameUrlParam}`)} value="Jogar" />
        </div>
      ) : mission?.video?.link_video && !disabled && mission.video?.status !== 'D' ? (
        <>
          <p>
            <span className="font-extrabold">Missão:</span> {mission?.name}
          </p>
          <div className="relative w-full" ref={videoRenderParentRef}>
            <img
              src={`img/trash.png`}
              onClick={() => deleteVideo()}
              className="absolute right-2 top-2 z-10 w-16 cursor-pointer"
              alt=""
            />
            <VideoRender
              parentRef={videoRenderParentRef}
              service={mission.video.service}
              video={mission.video.link_video}
            />
          </div>
          <EvaluationsList
            evaluations={{
              content: approvedContent,
              duration: approvedDuration,
              originality: approvedOriginality,
            }}
          />
          <div className="flex w-full flex-col items-start gap-4">
            <div className="flex gap-2">
              <p className="text-2xl font-extrabold">Avatar:</p>
              <p className="text-2xl">{localStorage.getItem('avatarName')}</p>
            </div>
            <div className="flex gap-2">
              <p className="text-2xl font-extrabold">Cidade:</p>
              <p className="text-2xl">{localStorage.getItem('city')}</p>
            </div>
            <div className="flex gap-2">
              <p className="text-2xl font-extrabold">Mundo:</p>
              <p className="text-2xl">{world?.name}</p>
            </div>
            <div className="flex gap-2">
              <p className="text-2xl font-extrabold">Jogo:</p>
              <p className="text-2xl">{game}</p>
            </div>
            <div className="flex gap-2">
              <p className="text-2xl font-extrabold">Missão:</p>
              <p className="text-2xl">{mission?.video?.mission}</p>
            </div>
            <div className="flex gap-2">
              <p className="text-2xl font-extrabold">Status do vídeo:</p>
              <p className="text-2xl">{videoStatus[mission?.videoStatus!]}</p>
            </div>
            <div className="flex gap-2">
              <p className="text-2xl font-extrabold">Pontuação virtual:</p>
              <p className="text-2xl">{mission?.virtualPoints}</p>
            </div>
            <div className="flex gap-2">
              <p className="text-2xl font-extrabold">Pontuação do vídeo:</p>
              <p className="text-2xl">{mission?.videoPoints}</p>
            </div>
            <div className="flex gap-2">
              <p className="text-2xl font-extrabold">Pontuação total:</p>
              <p className="text-2xl">{mission?.finalPoints}</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <p className="text-2xl font-medium">
            Parabéns! você completou os desafios de {mission?.name}. Agora vamos para a missão?
            Insira abaixo o endereço do vídeo de sua missão e clique em salvar.
          </p>
          <div className="flex w-full gap-4">
            <Input onChange={(e) => setVideoLink(e.target.value)} value={videoLink} />
            <Button onClick={() => saveVideo()} value="Salvar" />
          </div>
          <p className="text-2xl">Mas qual é a missão mesmo?</p>
          <Button
            onClick={() => window.open(gameData.video, '_blank')}
            value="Veja novamente o vídeo da missão"
          />
          <Button
            onClick={() => window.open('https://youtu.be/RZ6NjGFQTtM?feature=shared', '_blank')}
            value="Veja como inserir o vídeo da missão"
          />
        </>
      )}
    </Card>
  );
};
