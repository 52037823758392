import React, { useCallback, useState } from 'react';
import { PageLayout } from '../../components/page-layout';
import PageMargin from '../../components/page-margin/page-margin';
import Button from '../../components/common/Button';
import { getPerformance } from '../../service';
import { useLoading } from '../../hooks/use-loading';
import { FilterComponent, Filters } from '../../components/filter-component';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import PaginatedList from '../../components/common/PaginatedList';
import { useNavigate } from 'react-router-dom';
import { formatDateFromString } from '../../util/formatDate';
import { EmphasisTitle } from '../../components/common/EmphasisTitle';

interface ICardProps {
  id?: string;
  color: string;
  title: string;
  value: string | number | undefined;
  onSelect?: () => void;
}

type TPerformance = {
  playerPerformance: PlayerPerformance;
  teacherPerformance: TeacherPerformance;
};

const PerformancePanel: React.FC = () => {
  const navigate = useNavigate();
  const [performance, setPerformance] = React.useState<TPerformance>();
  const [selectedItem, setSelectedItem] = useState<string>();
  const [showedList, setShowedList] = useState<string>();
  const { setIsLoading } = useLoading();
  const [filters, setFilters] = useState<Filters>({
    company: '',
    city: '',
    world: '',
    mission: '',
    rating: '',
    class: '',
    game: '',
    period: {
      start: '',
      end: '',
    },
  });

  React.useEffect(() => {
    // if ((filters.period && !filters.period.start?.length) || !filters.period?.end?.length) return;
    setIsLoading(true);
    getPerformance({
      period: {
        start: filters.period?.start,
        end: filters.period?.end,
      },
      company: filters.company,
      city: filters.city,
      world: filters.world,
      game: filters.game,
    })
      .then((response) => {
        if (!response) return;
        setPerformance({
          playerPerformance: response.playerPerformance,
          teacherPerformance: response.teacherPerformance,
        });
      })
      .catch((_err) => setPerformance(undefined))
      .finally(() => setIsLoading(false));
  }, [setIsLoading, filters]);

  const FilterCard = ({ color, title, value }: ICardProps) => {
    return (
      <div
        className={`flex h-48 w-full flex-col items-center justify-center gap-4 rounded-2xl ${color}`}
      >
        <h2 className="px-8 text-center text-3xl font-extrabold">{title}</h2>
        <p className="px-8 text-center text-4xl">{value}</p>
      </div>
    );
  };

  const ResultCard = ({ id, color, title, value, onSelect }: ICardProps) => {
    return (
      <div
        id={id}
        className={`flex h-48 w-full flex-col items-center justify-center gap-4 rounded-2xl ${color}`}
      >
        <h2 className="px-8 text-center text-3xl">{title}</h2>
        <p className="px-8 text-center text-4xl font-extrabold">{value || 0}</p>
        <div className="absolute bottom-3 right-6">
          <input
            className="h-10 w-10"
            type="checkbox"
            onChange={onSelect}
            checked={id === selectedItem}
          />
        </div>
      </div>
    );
  };

  const handleDownloadExcel = (jsonData: string[] | undefined, fileName: string) => {
    if (!jsonData || !jsonData.length) {
      toast.error('Nenhum dado encontrado');
      return;
    }
    const dataWithHeader = [...jsonData.map((item) => [item])];

    const worksheet = XLSX.utils.aoa_to_sheet(dataWithHeader);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  const handleSelectItem = (value: string) => {
    setShowedList(undefined);
    setSelectedItem((prevItem) => {
      if (prevItem === value) {
        return undefined;
      }
      return value;
    });
  };

  const getCardsList = (cardId: string | undefined) => {
    if (!cardId) return [];
    const list = document.getElementById(cardId);
    if (list) {
      const listItems: string[] = [];
      list.childNodes.forEach((item) => {
        if (item instanceof HTMLDivElement) {
          listItems.push(item.id);
        }
      });
      return listItems;
    }
    return [];
  };

  const handleDownloadSelectedList = (listName: string, type: string) => {
    const listItems = getCardsList(listName);
    if (!selectedItem) return toast.error('Selecione um item');
    if (listItems.includes(selectedItem)) {
      const jsonData =
        type === 'player'
          ? performance?.playerPerformance[selectedItem as keyof PlayerPerformance]
          : performance?.teacherPerformance[selectedItem as keyof TeacherPerformance];

      if (!jsonData) return toast.error('Nenhum dado encontrado');

      if (selectedItem !== 'totalTeachersWithClass') {
        //@ts-ignore
        return handleDownloadExcel(jsonData, selectedItem);
      }
      //@ts-ignore
      const teacherEmails = jsonData.map((item: TeacherReturn) => item.teacher_email || '');
      return handleDownloadExcel(teacherEmails, selectedItem);
    }
  };

  const handleShowList = (listName: string) => {
    const listItems = getCardsList(listName);
    if (!selectedItem) return toast.error('Selecione um item');
    if (listItems.includes(selectedItem)) {
      setShowedList((prevList) => {
        if (prevList === selectedItem) {
          return undefined;
        }
        return selectedItem;
      });
    }
  };

  const renderPlayerItems = useCallback(() => {
    const items = performance?.playerPerformance[selectedItem as keyof PlayerPerformance];
    return items
      ? items.map((item, index) => {
          const userRow = item.split(';');
          return (
            <div key={index}>
              <p className="py-4 text-3xl leading-relaxed">
                <strong>Nome do jogador:</strong>{' '}
                {userRow[0] && userRow[0] !== 'undefined' ? userRow[0] : 'N/A'}
                <br />
                <strong>Email:</strong> {userRow[1] || ''}
              </p>
            </div>
          );
        })
      : [];
  }, [performance, selectedItem]);

  const renderTeacherItems = useCallback(() => {
    const items = performance?.teacherPerformance[selectedItem as keyof TeacherPerformance];
    return items
      ? items.map((item, index) => {
          if (typeof item === 'string') {
            return (
              <div key={index}>
                <p className="py-4 text-3xl leading-relaxed">
                  <strong>Email do professor:</strong> {item || ''}
                </p>
              </div>
            );
          }
          return (
            <div key={index}>
              <p className="py-4 text-3xl leading-relaxed">
                <strong>Nome do professor:</strong> {item.name || ''}
                <br />
                <strong>Email do professor:</strong> {item.teacher_email || ''}
                <br />
                <strong>Escola:</strong> {item.school || ''}
                <strong>Código de Turma:</strong> {item.code || ''}
                <br />
                <strong>Quantidade de alunos na turma de Turma: {item.userCount}</strong>{' '}
                {/* {item.userEmails} */}
              </p>
            </div>
          );
        })
      : [];
  }, [performance, selectedItem]);

  return (
    <PageLayout pageTitle="Relatório de Performance">
      <PageMargin className="mt-16 flex-1">
        <Button
          value="Voltar"
          onClick={() => navigate('/superadmin-dashboard')}
          className="absolute -top-24 hidden w-52 lg:inline"
        />
        <div className="flex flex-col items-center justify-center lg:hidden">
          <h1 className="mt-16 text-center text-4xl leading-relaxed">
            Esta página está disponível apenas para Desktop
          </h1>
        </div>
        <div className="mt-16 hidden lg:flex lg:flex-col">
          <EmphasisTitle className="mb-10 font-semibold sm:leading-relaxed">
            Olá, bem vinda(o) ao Relatório de Performance Aqui você encontra os resultados dos jogos
            e cria relatórios com base nos dados coletados.
          </EmphasisTitle>
          <div className="flex flex-col gap-4">
            <div className="mb-8 flex flex-row items-center justify-start gap-8">
              <FilterComponent
                filters={filters}
                setFilters={setFilters}
                enableCompanyFilter
                enableCityFilter
                enableRatingFilter={false}
                hideClassFilter
                enablePeriodFilter
                enableMissionFilter={false}
                enableShowFiltered={false}
                disablePeriodFilter
              />
            </div>
            <div className="flex h-24 w-full items-center rounded-3xl bg-[#C4E4F5] p-12 text-3xl sm:text-4xl">
              <p className="mr-4 font-extrabold">Período: </p>
              <p className="font-medium">
                {filters.period && filters.period.start && filters.period.end
                  ? formatDateFromString(filters.period.start) +
                    ' a ' +
                    formatDateFromString(filters.period.end)
                  : 'Todo o período'}
              </p>
            </div>
            <div className="mt-8 grid w-full grid-cols-3 gap-8">
              <FilterCard color="bg-[#88D4FD]" title="Empresa" value={filters.company || 'Todas'} />
              <FilterCard color="bg-[#9DDDFF]" title="Cidade" value={filters.city || 'Todas'} />
              <FilterCard color="bg-[#B6E5FF]" title="Mundo" value={filters.world || 'Todos'} />
              <FilterCard color="bg-[#9AC9E3]" title="Jogo" value={filters.game || 'Todos'} />
              <FilterCard color="bg-[#73C7F4]" title="Missão" value={filters.mission || 'Todas'} />
              <FilterCard
                color="bg-[#A4D2EA]"
                title="Avaliação"
                value={filters.rating || 'Todas'}
              />
            </div>
            <div className="mt-8 flex h-24 w-full items-center justify-center rounded-3xl bg-[#5AADFF] p-12 text-3xl sm:text-4xl">
              <p className="font-bold">
                {performance?.playerPerformance.totalUsers.length} Jogadores
              </p>
            </div>
            <div className="flex w-full flex-row justify-end">
              <Button
                className="text-xl md:min-w-fit md:text-2xl"
                value="Baixar Lista"
                onClick={() =>
                  handleDownloadExcel(performance?.playerPerformance.totalUsers, 'players')
                }
              />
            </div>
            <h2 className="mb-4 text-4xl font-bold">Performance dos Jogadores</h2>
            <div id="playerResultList" className="grid w-full grid-cols-3 gap-8">
              <ResultCard
                id="totalUsersPlayedWithoutTokens"
                color="bg-[#FCCF8C]"
                title="Jogadores que iniciaram o jogo mas não ganharam tokens"
                value={performance?.playerPerformance.totalUsersPlayedWithoutTokens.length}
                onSelect={() => handleSelectItem('totalUsersPlayedWithoutTokens')}
              />
              <ResultCard
                id="totalUsersPlayedWith1BronzeToken"
                color="bg-[#FCCF8C]"
                title="Jogadores da primeira semana que ganharam um token bronze"
                value={
                  performance?.playerPerformance.totalUsersPlayedWith1BronzeToken.length ===
                  performance?.playerPerformance.totalUsers.length
                    ? 'Todos'
                    : performance?.playerPerformance.totalUsersPlayedWith1BronzeToken.length
                }
                onSelect={() => handleSelectItem('totalUsersPlayedWith1BronzeToken')}
              />
              <ResultCard
                id="totalUsersPlayedWithMoreThan1BronzeToken"
                color="bg-[#FFBF5F]"
                title="Jogadores da primeira semana que ganharam dois token bronze"
                value={
                  performance?.playerPerformance.totalUsersPlayedWithMoreThan1BronzeToken.length
                }
                onSelect={() => handleSelectItem('totalUsersPlayedWithMoreThan1BronzeToken')}
              />
              <ResultCard
                id="totalUsersPlayedWith1SilverToken"
                color="bg-[#E2EBF0]"
                title="Jogadores da segunda semana que ganharam um token de prata"
                value={
                  performance?.playerPerformance.totalUsersPlayedWith1SilverToken.length ===
                  performance?.playerPerformance.totalUsers.length
                    ? 'Todos'
                    : performance?.playerPerformance.totalUsersPlayedWith1SilverToken.length
                }
                onSelect={() => handleSelectItem('totalUsersPlayedWith1SilverToken')}
              />
              <ResultCard
                id="totalUsersPlayedWithMoreThan1SilverToken"
                color="bg-[#C5D5DD]"
                title="Jogadores da segunda semana que ganharam dois token de prata"
                value={
                  performance?.playerPerformance.totalUsersPlayedWithMoreThan1SilverToken.length ===
                  performance?.playerPerformance.totalUsers.length
                    ? 'Todos'
                    : performance?.playerPerformance.totalUsersPlayedWithMoreThan1SilverToken.length
                }
                onSelect={() => handleSelectItem('totalUsersPlayedWithMoreThan1SilverToken')}
              />
              <ResultCard
                id="totalUsersPlayedWith1GoldToken"
                color="bg-[#FDEDB4]"
                title="Jogadores da terceira semana que ganharam um token de ouro"
                value={
                  performance?.playerPerformance.totalUsersPlayedWith1GoldToken.length ===
                  performance?.playerPerformance.totalUsers.length
                    ? 'Todos'
                    : performance?.playerPerformance.totalUsersPlayedWith1GoldToken.length
                }
                onSelect={() => handleSelectItem('totalUsersPlayedWith1GoldToken')}
              />
              <ResultCard
                id="totalUsersPlayedWithMoreThan1GoldToken"
                color="bg-[#FFDE6B]"
                title="Jogadores da terceira semana que ganharam dois tokens de ouro"
                value={
                  performance?.playerPerformance.totalUsersPlayedWithMoreThan1GoldToken.length ===
                  performance?.playerPerformance.totalUsers.length
                    ? 'Todos'
                    : performance?.playerPerformance.totalUsersPlayedWithMoreThan1GoldToken.length
                }
                onSelect={() => handleSelectItem('totalUsersPlayedWithMoreThan1GoldToken')}
              />
              <ResultCard
                id="totalPlayersThatNeedSendVideo"
                color="bg-[#FFE3E0]"
                title="Jogadores que precisam enviar o vídeo da missão"
                value={
                  performance?.playerPerformance.totalPlayersThatNeedSendVideo.length ===
                  performance?.playerPerformance.totalUsers.length
                    ? 'Todos'
                    : performance?.playerPerformance.totalPlayersThatNeedSendVideo.length
                }
                onSelect={() => handleSelectItem('totalPlayersThatNeedSendVideo')}
              />
              <ResultCard
                id="totalPlayersThatVideoStatusIsWaiting"
                color="bg-[#FFCBC5]"
                title="Jogadores que cujos vídeos não foram avaliados pela plataforma"
                value={
                  performance?.playerPerformance.totalPlayersThatVideoStatusIsWaiting.length ===
                  performance?.playerPerformance.totalUsers.length
                    ? 'Todos'
                    : performance?.playerPerformance.totalPlayersThatVideoStatusIsWaiting.length
                }
                onSelect={() => handleSelectItem('totalPlayersThatVideoStatusIsWaiting')}
              />
              <ResultCard
                id="totalPlayersThatVideoStatusIsApproval"
                color="bg-[#FFB0A7]"
                title="Jogadores que tiveram o vídeo aprovado pela plataforma"
                value={
                  performance?.playerPerformance.totalPlayersThatVideoStatusIsApproval.length ===
                  performance?.playerPerformance.totalUsers.length
                    ? 'Todos'
                    : performance?.playerPerformance.totalPlayersThatVideoStatusIsApproval.length
                }
                onSelect={() => handleSelectItem('totalPlayersThatVideoStatusIsApproval')}
              />
              <ResultCard
                id="totalPlayersThatVideoStatusIsReproved"
                color="bg-[#FF9C91]"
                title="Jogadores que tiveram o vídeo reprovado pela plataforma"
                value={
                  performance?.playerPerformance.totalPlayersThatVideoStatusIsReproved.length ===
                  performance?.playerPerformance.totalUsers.length
                    ? 'Todos'
                    : performance?.playerPerformance.totalPlayersThatVideoStatusIsReproved.length
                }
                onSelect={() => handleSelectItem('totalPlayersThatVideoStatusIsReproved')}
              />
              <ResultCard
                id="totalPlayersThatVideoStatusIsWaitingApproval"
                color="bg-[#FF8172]"
                title="Jogadores que estão aguardando a aprovação do vídeo pela plataforma"
                value={
                  performance?.playerPerformance.totalPlayersThatVideoStatusIsWaitingApproval
                    .length === performance?.playerPerformance.totalUsers.length
                    ? 'Todos'
                    : performance?.playerPerformance.totalPlayersThatVideoStatusIsWaitingApproval
                        .length
                }
                onSelect={() => handleSelectItem('totalPlayersThatVideoStatusIsWaitingApproval')}
              />
              <ResultCard
                id="totalPlayersThatVideoStatusIsDeleted"
                color="bg-[#FF6251]"
                title="Jogadores que apagaram o vídeo da missão"
                value={
                  performance?.playerPerformance.totalPlayersThatVideoStatusIsDeleted ===
                  performance?.playerPerformance.totalUsers.length
                    ? 'Todos'
                    : performance?.playerPerformance.totalPlayersThatVideoStatusIsDeleted.length
                }
                onSelect={() => handleSelectItem('totalPlayersThatVideoStatusIsDeleted')}
              />
            </div>
            <div className="mt-8 flex w-full flex-row justify-end gap-8">
              <Button
                className="text-xl md:min-w-fit md:text-2xl"
                value="Ver Lista"
                onClick={() => handleShowList('playerResultList')}
              />
              <Button
                className="text-xl md:min-w-fit md:text-2xl"
                value="Baixar Lista"
                onClick={() => handleDownloadSelectedList('playerResultList', 'player')}
              />
            </div>
            {showedList && getCardsList('playerResultList').includes(showedList) && (
              <div className="mb-8 flex flex-col">
                <PaginatedList
                  title={document.getElementById(showedList)?.childNodes[0].textContent || ''}
                  itemsPerPage={10}
                  renderItems={renderPlayerItems}
                />
              </div>
            )}

            <h2 className="mb-4 text-4xl font-bold">Jogadores que não iniciaram o jogo</h2>
            <div id="playerLoginResultList" className="grid w-full grid-cols-3 gap-8">
              <ResultCard
                id="totalUsersDidNotLoggedIn"
                color="bg-[#C0E8FF]"
                title="Jogadores que não confirmaram conta através do email"
                value={
                  performance?.playerPerformance.totalUsersDidNotLoggedIn.length ===
                  performance?.playerPerformance.totalUsers.length
                    ? 'Todos'
                    : performance?.playerPerformance.totalUsersDidNotLoggedIn.length
                }
                onSelect={() => handleSelectItem('totalUsersDidNotLoggedIn')}
              />
              <ResultCard
                id="totalUsersLoggedInAndNotPlayed"
                color="bg-[#A2DDFF]"
                title="Jogadores que passaram da fase de login mas não começaram a jogar"
                value={
                  performance?.playerPerformance.totalUsersLoggedInAndNotPlayed.length === 0
                    ? 'Todos'
                    : performance?.playerPerformance.totalUsersLoggedInAndNotPlayed.length
                }
                onSelect={() => handleSelectItem('totalUsersLoggedInAndNotPlayed')}
              />
            </div>
            <div className="mt-8 flex w-full flex-row justify-end gap-8">
              <Button
                className="text-xl md:min-w-fit md:text-2xl"
                value="Ver Lista"
                onClick={() => handleShowList('playerLoginResultList')}
              />
              <Button
                className="text-xl md:min-w-fit md:text-2xl"
                value="Baixar Lista"
                onClick={() => handleDownloadSelectedList('playerLoginResultList', 'player')}
              />
            </div>
            {showedList && getCardsList('playerLoginResultList').includes(showedList) && (
              <div className="mb-8 flex flex-col">
                <PaginatedList
                  title={document.getElementById(showedList)?.childNodes[0].textContent || ''}
                  itemsPerPage={10}
                  renderItems={renderPlayerItems}
                />
              </div>
            )}
            <h2 className="mb-4 text-4xl font-bold">Performance dos Professores</h2>
            <div id="teacherResultList" className="grid w-full grid-cols-3 gap-8">
              <ResultCard
                id="totalTeachersDidNotLoggedIn"
                color="bg-[#C0E8FF]"
                title="Professores que não passaram da fase de login"
                value={
                  performance?.teacherPerformance.totalTeachersDidNotLoggedIn.length ===
                  performance?.teacherPerformance.totalTeachers.length
                    ? 'Todos'
                    : performance?.teacherPerformance.totalTeachersDidNotLoggedIn.length
                }
                onSelect={() => handleSelectItem('totalTeachersDidNotLoggedIn')}
              />
              <ResultCard
                id="totalTeacherLoggedIn"
                color="bg-[#A2DDFF]"
                title="Professores que passaram da fase de login mas não criaram Código de Turma"
                value={
                  performance?.teacherPerformance.totalTeacherLoggedIn ===
                  performance?.teacherPerformance.totalTeachers.length
                    ? 'Todos'
                    : performance?.teacherPerformance.totalTeacherLoggedIn.length
                }
                onSelect={() => handleSelectItem('totalTeacherLoggedIn')}
              />
              <ResultCard
                id="totalTeachersWithClass"
                color="bg-[#FCCF8C]"
                title="Professores com Códigos de Turma criados"
                value={
                  performance?.teacherPerformance.totalTeachersWithClass.length ===
                  performance?.teacherPerformance.totalTeachers.length
                    ? 'Todos'
                    : performance?.teacherPerformance.totalTeachersWithClass.length
                }
                onSelect={() => handleSelectItem('totalTeachersWithClass')}
              />
            </div>
            <div className="mt-8 flex w-full flex-row justify-end gap-8">
              <Button
                className="text-xl md:min-w-fit md:text-2xl"
                value="Ver Lista"
                onClick={() => handleShowList('teacherResultList')}
              />
              <Button
                className="text-xl md:min-w-fit md:text-2xl"
                value="Baixar Lista"
                onClick={() => handleDownloadSelectedList('teacherResultList', 'teacher')}
              />
            </div>
            {showedList && getCardsList('teacherResultList').includes(showedList) && (
              <div className="mb-8 flex flex-col">
                <PaginatedList
                  title={document.getElementById(showedList)?.childNodes[0].textContent || ''}
                  itemsPerPage={showedList === 'totalTeachersWithClass' ? 3 : 10}
                  renderItems={renderTeacherItems}
                />
              </div>
            )}
          </div>
        </div>
      </PageMargin>
    </PageLayout>
  );
};

export default PerformancePanel;
