import React from 'react';
import PageMargin from '../../components/page-margin/page-margin';
import { EmphasisTitle } from '../../components/common/EmphasisTitle';
import { Paragraph } from '../../components/common/Paragraph';

export const Punctuation: React.FC = () => {
  return (
    <PageMargin>
      <div className="flex flex-1 flex-col gap-10 p-10 font-[Raleway]">
        <h1 className="text-4xl font-bold">Pontuação</h1>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          Pontuação na Cidade Virtual - Primeira Semana
        </EmphasisTitle>
        <Paragraph>
          <b>Pontos X Tokens: </b>na primeira semana, o jogador ganha tokens de bronze. 
        </Paragraph>
        <div className="flex flex-row items-center gap-2">
          <img
            className="h-10 w-10 md:h-12 md:w-12"
            src="img/icons/bronze-token.png"
            alt="bronze-token"
          />
          <Paragraph>570 pontos = 1 token de bronze.</Paragraph>
        </div>
        <div className="flex flex-row items-center gap-2">
          <img
            className="h-10 w-10 md:h-12 md:w-12"
            src="img/icons/bronze-token.png"
            alt="bronze-token"
          />
          <img
            className="h-10 w-10 md:h-12 md:w-12"
            src="img/icons/bronze-token.png"
            alt="bronze-token"
          />
          <Paragraph>
            O jogador termina a primeira semana acumulando 1.150 pontos e 2 Tokens de bronze.
          </Paragraph>
        </div>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          Pontuação na Cidade Virtual - Segunda Semana
        </EmphasisTitle>
        <Paragraph>
          <b>Pontos X Tokens: </b>na segunda semana, o jogador ganha tokens de prata. 
        </Paragraph>
        <div className="flex flex-row items-center gap-2">
          <img
            className="h-10 w-10 md:h-12 md:w-12"
            src="img/icons/silver-token.png"
            alt="silver-token"
          />
          <Paragraph>950 pontos = 1 token de prata.</Paragraph>
        </div>
        <div className="flex flex-row items-center gap-2">
          <img
            className="h-10 w-10 md:h-12 md:w-12"
            src="img/icons/silver-token.png"
            alt="silver-token"
          />
          <img
            className="h-10 w-10 md:h-12 md:w-12"
            src="img/icons/silver-token.png"
            alt="silver-token"
          />
          <Paragraph>
            O jogador termina a segunda semana acumulando 1.980 pontos e 2 Tokens de prata.
          </Paragraph>
        </div>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          Pontuação na Cidade Virtual - Terceira Semana
        </EmphasisTitle>
        <Paragraph>
          <b>Pontos X Tokens: </b>na terceira semana, o jogador ganha tokens de prata. 
        </Paragraph>
        <div className="flex flex-row items-center gap-2">
          <img
            className="h-10 w-10 md:h-12 md:w-12"
            src="img/icons/golden-token.png"
            alt="golden-token"
          />
          <Paragraph>1.280 pontos = 1 token de ouro.</Paragraph>
        </div>
        <div className="flex flex-row items-center gap-2">
          <img
            className="h-10 w-10 md:h-12 md:w-12"
            src="img/icons/golden-token.png"
            alt="golden-token"
          />
          <img
            className="h-10 w-10 md:h-12 md:w-12"
            src="img/icons/golden-token.png"
            alt="golden-token"
          />
          <Paragraph>
            O jogador termina a primeira semana acumulando entre 2.570 pontos e 2.870 pontos e 2
            Tokens de ouro.
          </Paragraph>
        </div>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          Somatória dos pontos na cidade virtual:
        </EmphasisTitle>
        <Paragraph>
          <b>Mínimo: </b>5.700 pontos e 6 tokens
        </Paragraph>
        <Paragraph>
          <b>Máximo: </b>6.000 pontos e 6 tokens
        </Paragraph>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          Pontuação do Mundo Real
        </EmphasisTitle>
        <Paragraph>Regras de avaliação dos vídeos:</Paragraph>
        <div className="flex flex-row items-start gap-4">
          <img
            className="mt-2 h-10 w-10 md:h-12 md:w-12"
            src="img/icons/blue-check.png"
            alt="content"
          />
          <Paragraph>
            <b>Conteúdo: </b>o vídeo será avaliado entre 0 e 1.000 pontos no quesito conteúdo.
            <br /> Se o vídeo alcançar um valor igual ou maior do que 500 pontos, é aprovado neste
            quesito.
          </Paragraph>
        </div>
        <div className="flex flex-row items-start gap-4">
          <img
            className="mt-2 h-10 w-10 md:h-12 md:w-12"
            src="img/icons/green-check.png"
            alt="duration"
          />
          <Paragraph>
            <b>Duração: </b>o vídeo será avaliado entre 0 e 1.000 pontos no quesito conteúdo.
            <br /> Se o vídeo alcançar um valor igual ou maior do que 500 pontos, é aprovado neste
            quesito.
          </Paragraph>
        </div>
        <div className="flex flex-row items-start gap-4">
          <img
            className="mt-2 h-10 w-10 md:h-12 md:w-12"
            src="img/icons/pink-check.png"
            alt="originality"
          />
          <Paragraph>
            <b>Originalidade: </b>o vídeo será avaliado entre 0 e 2.000 pontos no quesito conteúdo.
          </Paragraph>
        </div>
        <Paragraph>
          O Vídeo somente é aprovado e passa a aparecer na Galeria Geral quando aprova pelo menos os
          quesitos de conteúdo e de duração.
        </Paragraph>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          Somatória dos pontos no mundo real:
        </EmphasisTitle>
        <Paragraph>
          <b>Mínimo: </b>500 pontos do 1o Quesito + 500 pontos do 2o Quesito + 0 pontos 3o do
          Quesito = 1.000 pontos
        </Paragraph>
        <Paragraph>
          <b>Máximo: </b>1.000 pontos do 1o Quesito + 1.000 pontos do 2o Quesito + 2.000 pontos do
          3o Quesito = 4.000 pontos
        </Paragraph>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          Somatória dos pontos na cidade virtual + mundo real:
        </EmphasisTitle>
        <Paragraph>
          <b>Mínimo: </b>5.700 pontos + 1.000 pontos = 6.700 pontos
        </Paragraph>
        <Paragraph>
          <b>Máximo: </b>6.000 pontos + 4.000 pontos = 10.000 pontos
        </Paragraph>
      </div>
    </PageMargin>
  );
};
