import React from 'react';
import { useState } from 'react';
import { GameCard } from './GameCard';
import { useNavigate } from 'react-router-dom';
import { isProduction } from '../../../hooks/isProduction';
import { EmphasisTitle } from '../../common/EmphasisTitle';

interface IHomeBannerProps {
  handleClientClick: (client: string) => void;
  display?: boolean;
}

export const HomeBanner: React.FC<IHomeBannerProps> = ({ handleClientClick, display = true }) => {
  const navigate = useNavigate();
  const [waitingRelease, setWaitingRelease] = useState(false);

  const handleEvent = (event: any) => {
    setWaitingRelease(event.detail);
  };

  window.addEventListener('waitingRelease', handleEvent);

  return (
    <div className="flex w-full flex-col">
      <EmphasisTitle className="text mb-8 text-subtitle">Escolha um jogo:</EmphasisTitle>
      <div className={`${!display && 'hidden'}`}>
        <div className="grid gap-8 sm:grid-cols-2">
          <GameCard
            id="water-reuse-game-card"
            alt="Jogo Reúso de Água"
            imagePath="/gif/minigame-water2.gif"
            onClick={() => {
              navigate('/game/water-reuse');
            }}
            clickable
          />
          <GameCard
            alt="Jogo História do Saneamento"
            imagePath="/gif/minigame-sanitation.gif"
            onClick={() => {
              navigate('/game/sanitation-history');
            }}
            clickable
          />
          <GameCard
            alt="Jogo Resíduos Orgânicos"
            imagePath="/gif/minigame-waste2.gif"
            onClick={() => {
              navigate('/game/recyclable-waste');
            }}
          />
          <GameCard alt="Jogo Resíduos Recicláves" imagePath="/img/minigame-waste.jpeg" disabled />
        </div>
      </div>
    </div>
  );
};
