import React from 'react';
import { Paragraph } from './Paragraph';
import { GoogleOAuthProvider } from '@react-oauth/google';
import OAuthIcons from './OAuthIcons';
import { GOOGLE_CLIENT_ID } from '../../config';
import logoTikTok from '../../tiktok.png';
import { usePlaying } from '../../context/playing';
import { tikTokOauth } from '../../service';
import { useNavigate } from 'react-router-dom';
import getHref from '../../util/getHref';

interface ISocialLoginProps {
  setLoginError: React.Dispatch<React.SetStateAction<boolean>>;
  hasAccount: boolean;
  hasTiktok?: boolean;
  hasStudent?: boolean;
  authType?: string;
  showTerms?: boolean;
}

export const SocialLogin: React.FC<ISocialLoginProps> = ({
  setLoginError,
  hasAccount,
  hasTiktok = true,
  hasStudent = false,
  authType = 'USER',
  showTerms = true,
}) => {
  const { origin } = usePlaying();
  const navigate = useNavigate();

  const handleTikTokButtonPressed = async () => {
    const response = await tikTokOauth();
    const url = response.data.url;
    window.location.href = url;
  };

  if (origin.includes('neoenergia')) {
    return <div className="mt-8" />;
  }

  return (
    <div className="mt-8 flex w-full flex-col items-center justify-center">
      <Paragraph className="font-bold">
        {hasAccount ? 'Ou crie sua conta com:' : 'Ou entre com sua conta:'}
      </Paragraph>
      <div className="my-8 flex cursor-pointer flex-row items-center gap-8">
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <OAuthIcons
            setLoginError={setLoginError}
            authType={authType}
            className="h-20 transform cursor-pointer hover:scale-110"
          />
        </GoogleOAuthProvider>
        {hasTiktok && (
          <img
            onClick={handleTikTokButtonPressed}
            src={logoTikTok}
            alt="TikTok"
            className="h-20 transform cursor-pointer hover:scale-110"
          />
        )}
        {hasStudent && (
          <img
            onClick={() => navigate(getHref('register-student'))}
            src={'img/icons/student.png'}
            title="Crie sua conta como aluno"
            alt="Criar conta como estudante"
            className="h-20 transform cursor-pointer hover:scale-110"
          />
        )}
      </div>
      <div className="flex w-full flex-col items-center justify-center">
        <div className="flex flex-row items-center gap-2">
          <Paragraph className="font-bold">
            {hasAccount ? 'Já tem conta? ' : 'Não tem conta? '}
          </Paragraph>
          <div
            className="cursor-pointer text-2xl font-bold leading-relaxed text-secondary md:text-3xl md:leading-relaxed"
            onClick={() => {
              hasAccount
                ? navigate(getHref(authType === 'USER' ? 'login' : 'login-professor'))
                : navigate(getHref(authType === 'USER' ? 'register' : 'cadastro-professor'));
            }}
          >
            {hasAccount ? 'Entre com sua conta >' : 'Crie sua conta >'}
          </div>
        </div>
      </div>
      {showTerms && (
        <div className="my-8 w-full whitespace-normal break-words text-center text-xl font-semibold">
          <div className="inline-block w-fit">
            <p>
              Ao criar uma conta, você concorda com os{' '}
              <a href="/terms-and-conditions" className="text-secondary" target="blank">
                Termos e Condições{' '}
              </a>{' '}
              <br className="hidden sm:block" />
              <a href="/privacy-policy" target="blank">
                e a Política de Privacidade
              </a>{' '}
              da The Blue City.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
