import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PageLayout } from '../../../components/page-layout';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  getTeacherListClass,
  getTeacherProgressUsersByClass,
  registerUser,
} from '../../../service';

import Button from '../../../components/common/Button';
import PageMargin from '../../../components/page-margin/page-margin';
import { EmphasisTitle } from '../../../components/common/EmphasisTitle';
import ModalComponent, { IModalRef } from '../../../components/common/Modal';
import { InputForm } from '../../../components/Input-form';
import { toast } from 'react-toastify';
import { useLoading } from '../../../hooks/use-loading';
import { SelectForm } from '../../../components/select-form';
import { Paragraph } from '../../../components/common/Paragraph';
import { StudentCardProgress } from './components/student-progress-card/student-progress-card';
import PaginatedStudentList from '../../../components/common/PaginatedStudentList';

interface FormValues {
  name: string;
  bronzeTokens: number;
  silverTokens: number;
  goldTokens: number;
}

interface FormTokenValues {
  name: string;
  world: string;
  game: string;
  points: number;
  tokens: number;
}

type Points = {
  points: number;
  tokens: number;
};

type Games = {
  [key: string]: Points;
};

type Worlds = Record<string, Games>;

const validationSchema = Yup.object({
  name: Yup.string().required('Campo obrigatório'),
  bronzeTokens: Yup.number().min(0, 'A quantidade deve ser positiva').required('Campo obrigatório'),
  silverTokens: Yup.number().min(0, 'A quantidade deve ser positiva').required('Campo obrigatório'),
  goldTokens: Yup.number().min(0, 'A quantidade deve ser positiva').required('Campo obrigatório'),
});

const validationTokensSchema = Yup.object({
  name: Yup.string().required('Campo obrigatório'),
  world: Yup.string().required('Campo obrigatório'),
  game: Yup.string().required('Campo obrigatório'),
  points: Yup.number()
    .min(0, 'A quantidade deve ser positiva')
    .max(1000, 'A quantidade deve ser menor ou igual a 1000')
    .required('Campo obrigatório'),
  tokens: Yup.number()
    .min(0, 'A quantidade deve ser positiva')
    .max(2, 'A quantidade deve ser menor ou igual a 2')
    .required('Campo obrigatório'),
});

export const TeacherClass = () => {
  const { id } = useParams();

  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [registerStudentMessage, setRegisterStudentMessage] = React.useState<string>('');
  const [worlds, setWorlds] = React.useState<Worlds>({
    'O mundo da água': {
      'Reúso de Água': {
        points: 0,
        tokens: 0,
      },
      'Economia de Água': {
        points: 0,
        tokens: 0,
      },
    },
    'O mundo do saneamento': {
      'A história do saneamento': {
        points: 0,
        tokens: 0,
      },
      'Saneamento e saúde': {
        points: 0,
        tokens: 0,
      },
    },
    'O mundo dos resíduos': {
      'Resíduos recicláveis': {
        points: 0,
        tokens: 0,
      },
      'Resíduos orgânicos': {
        points: 0,
        tokens: 0,
      },
    },
  });
  const { setIsLoading } = useLoading();

  const modalRef = React.useRef<IModalRef>(null);
  const modalTokensRef = React.useRef<IModalRef>(null);

  const students = useQuery({
    queryKey: ['students'],
    queryFn: () => getTeacherProgressUsersByClass(id),
    enabled: !!id,
  });

  const { data: classes } = useQuery({ queryKey: ['classes'], queryFn: getTeacherListClass });

  React.useEffect(() => {
    if (students.isLoading) {
      return setIsLoading(true);
    }
    return setIsLoading(false);
  }, [students.isLoading, setIsLoading]);

  const group = React.useMemo(() => {
    if (!classes?.data) {
      return null;
    }
    return classes?.data.find((c: any) => c.code === id);
  }, [classes?.data, id]);

  const navigate = useNavigate();

  const handleCreateStudent = async (values: FormValues) => {
    try {
      setLoading(true);
      const result = await registerUser(values.name, Number(group?.id));
      if (result.data) {
        setRegisterStudentMessage('Aluno criado com sucesso!');
        formik.resetForm();
        setIsLoading(true);
        await students.refetch();
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error;
      const errors: Record<string, string> = {
        'User already exists': 'Aluno já cadastrado',
      };
      setRegisterStudentMessage(String((errors[errorMessage] as String) || errorMessage));
    } finally {
      setIsLoading(false);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      bronzeTokens: 0,
      silverTokens: 0,
      goldTokens: 0,
    },
    onSubmit: handleCreateStudent,
    validationSchema: validationSchema,
  });

  const handleSetProgress = async (values: FormTokenValues) => {
    try {
      setLoading(true);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error;
      const errors: Record<string, string> = {
        'User already exists': 'Aluno já cadastrado',
      };
      toast.error((errors[errorMessage] as String) || errorMessage);
    } finally {
      setIsLoading(false);
      setLoading(false);
    }
  };

  const formikTokens = useFormik({
    initialValues: {
      name: '',
      world: '',
      game: '',
      points: 0,
      tokens: 0,
    },
    onSubmit: handleSetProgress,
    validationSchema: validationTokensSchema,
  });

  const renderStudents = React.useMemo((): JSX.Element[] => {
    if (!students?.data || !Object.entries(students?.data).length) {
      return [
        <div className="flex flex-col items-center justify-center">
          <h1 className="mt-16 text-center text-4xl leading-relaxed">Nenhum aluno encontrado!</h1>
        </div>,
      ];
    }
    return Object.entries(students?.data).map(([_, student]: [string, any], idx) => (
      <StudentCardProgress key={idx} student={student} students={students} classCode={id} />
    ));
  }, [id, students]);

  React.useEffect(() => {
    if (registerStudentMessage) {
      setTimeout(() => {
        setRegisterStudentMessage('');
      }, 4000);
    }
  }, [registerStudentMessage]);

  return (
    <PageLayout pageTitle="Painel do Professor" marginFromTitle>
      <PageMargin>
        <EmphasisTitle className="font-semibold sm:leading-relaxed">
          Professora ou professor, bem-vinda(o) ao Painel do Professor. Aqui você acompanha o status
          de jogo de seus alunos filtrados por Código de Turma. Você pode adicionar outros filtros.
        </EmphasisTitle>
        <div className="flex h-10 flex-row items-center rounded-3xl bg-[#C4E4F5] px-12 py-10">
          <div className="w-full whitespace-normal break-words text-2xl sm:text-4xl">
            <p className="inline-block w-fit pr-2 font-extrabold">Filtro por código de turma:</p>
            <span className="inline-block cursor-pointer pr-2 text-start text-2xl font-semibold sm:text-4xl">
              {id}
            </span>
          </div>
        </div>
        <div className="flex flex-row items-center justify-between">
          {group ? (
            <h1 className="w-fit text-4xl font-bold">
              Turma: {group?.year}{' '}
              <span className="font-normal">
                ({group?.code}) - <b> Turma {group?.type}</b>
              </span>
            </h1>
          ) : (
            <div />
          )}
          <div className="flex flex-row gap-4">
            <Button
              value="Cadastrar aluno para login simplificado"
              onClick={() => {
                formik.resetForm();
                modalRef?.current?.handleModal();
              }}
            />
            <Button value="Voltar" onClick={() => navigate('/painelprofessor')} />
          </div>
        </div>
        <div className="flex flex-col gap-8">
          <PaginatedStudentList
            renderItems={() => renderStudents}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
        <ModalComponent ref={modalRef} width="50%" height="fit-content">
          <form
            onSubmit={formik.handleSubmit}
            className="my-36 flex w-full flex-col items-center justify-center gap-8"
          >
            <EmphasisTitle className=" text-secondary">
              Cadastrar Aluno para login simplificado
            </EmphasisTitle>
            <Paragraph className="text text-center font-semibold">
              Olá professora(o), cadastre seu aluno com o nome completo:
            </Paragraph>
            <div className="mx-8 flex w-8/12 flex-col items-center justify-center gap-6 px-8">
              <InputForm
                className="px-8 text-start"
                name="classCode"
                label="Código de Turma"
                placeholder="Código de Turma"
                value={group?.code}
                disabled
              />
              <InputForm
                className="px-8 text-start"
                name="name"
                label="Nome Completo"
                placeholder="Nome completo do aluno"
                formik={formik}
                value={formik.values.name}
                error={formik.errors.name}
              />
              <Button
                className="w-full"
                type="submit"
                value={!loading ? 'Cadastrar' : 'Carregando...'}
                inactive={loading}
              />
            </div>

            {registerStudentMessage && (
              <Paragraph className="mt-2 text-left font-semibold text-[#DE3838]">
                {registerStudentMessage}
              </Paragraph>
            )}
          </form>
        </ModalComponent>
        <ModalComponent
          ref={modalTokensRef}
          width="50%"
          height="45%"
          maxWidth="80rem"
          maxHeight="100rem"
        >
          <form
            onSubmit={formikTokens.handleSubmit}
            className="flex w-full items-center justify-center"
          >
            <div className="mx-8 flex w-full flex-col items-center justify-center gap-6">
              <EmphasisTitle className="mb-8 text-secondary">Atualizar Progresso</EmphasisTitle>
              <InputForm
                className="px-8 text-start"
                name="name"
                label="Nome Completo"
                placeholder="Nome Completo"
                value={formikTokens.values.name}
                disabled
              />
              <SelectForm
                className="px-8 text-start"
                name="world"
                placeholder="Mundo"
                value={formikTokens.values.world}
                formik={formikTokens}
                options={Object.entries(worlds).map(([worldName, _]) => ({
                  value: worldName,
                  label: worldName,
                }))}
              />
              {formikTokens.values.world && (
                <SelectForm
                  className="px-8 py-4 text-start"
                  name="game"
                  placeholder="Jogo"
                  value={formikTokens.values.game}
                  formik={formikTokens}
                  options={Object.keys(worlds[formikTokens.values.world]).map((gameName) => {
                    return {
                      value: gameName,
                      label: gameName,
                    };
                  })}
                />
              )}
              {formikTokens.values.game && (
                <div className="flex w-full flex-row justify-center gap-8">
                  <div className="flex flex-col">
                    <Paragraph>Pontos</Paragraph>
                    <InputForm
                      className="px-8 text-start"
                      name="points"
                      label="Pontos"
                      placeholder="Pontos"
                      formik={formikTokens}
                      value={String(formikTokens.values.points)}
                      error={formikTokens.errors.points}
                    />
                  </div>
                  <div className="flex flex-col">
                    <Paragraph>Tokens</Paragraph>
                    <InputForm
                      className="px-8 text-start"
                      name="tokens"
                      label="Tokens"
                      placeholder="Tokens"
                      formik={formikTokens}
                      value={String(formikTokens.values.tokens)}
                      error={formikTokens.errors.tokens}
                    />
                  </div>
                </div>
              )}

              <Button
                className="w-full"
                type="submit"
                value={!loading ? 'Salvar' : 'Carregando...'}
                inactive={loading}
              />
            </div>
          </form>
        </ModalComponent>
      </PageMargin>
    </PageLayout>
  );
};
