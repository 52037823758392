import { ReactNode } from 'react';

interface WorldMissionListProps {
  children: ReactNode;
}

export const WorldMissionList = ({ children }: WorldMissionListProps) => {
  return (
    <div className="grid w-full grid-cols-1 flex-col place-items-start justify-center gap-8 sm:grid-cols-2 xl:flex xl:flex-col xl:items-start">
      {children}
    </div>
  );
};
