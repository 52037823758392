import * as React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import getHref from '../../util/getHref';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { registerStudent, updateStudentUserPassword } from '../../service';
import { InputForm } from '../Input-form';
import { PageLayout } from '../page-layout';
import { usePlaying } from '../../context/playing';
import { EmphasisTitle } from '../common/EmphasisTitle';
import { Paragraph } from '../common/Paragraph';
import Button from '../common/Button';
import { SocialLogin } from '../common/social-login';
import ModalComponent, { IModalRef } from '../common/Modal';
import { delay } from '../../util/delay';

interface FormValues {
  name: string;
  classCode: string;
}

interface FormRegisterValues {
  nickName: string;
  password: string;
  confirmPassword: string;
}

const validationSchema = Yup.object({
  name: Yup.string().required('Campo obrigatório'),
  classCode: Yup.string().required('Campo obrigatório'),
});

const validationRegisterSchema = Yup.object({
  nickName: Yup.string()
    .min(3, 'O Nickname deve ter no mínimo 3 caracteres')
    .required('Campo obrigatório'),
  password: Yup.string()
    .required('Campo obrigatório')
    .min(8, 'A senha deve ter no mínimo 8 caracteres'),
  confirmPassword: Yup.string()
    .required('Campo obrigatório')
    .oneOf([Yup.ref('password')], 'As senhas não coincidem'),
});

const RegisterStudent = () => {
  const [registerError, setRegisterError] = React.useState<boolean>(false);
  const [registerErrorMessage, setRegisterErrorMessage] = React.useState<string>();
  const [validationToken, setValidationToken] = React.useState<string>();
  const { origin } = usePlaying();
  const [loading, setIsLoading] = React.useState<boolean>(false);
  const modalRef = React.useRef<IModalRef>(null);

  const navigate = useNavigate();

  const handleCreateAccount = async (values: FormValues) => {
    try {
      setIsLoading(true);
      const result = await registerStudent(values.name, values.classCode, origin);
      if (result?.data) {
        setValidationToken(result?.data?.validationToken || '');
        modalRef?.current?.handleModal();
      }
    } catch (error: any) {
      const message = error?.response?.data?.error;
      const errors: Record<string, string> = {
        'User or class not found': 'Aluno ou turma não encontrada',
        'Name and code are required': 'Nome e turma são obrigatórios',
      };
      setRegisterErrorMessage('Olá, ' + errors[message] || message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegisterUserInfo = async (values: FormRegisterValues) => {
    if (!validationToken) {
      toast.error('Token expirado, tente novamente');
      formikRegister.resetForm();
      return modalRef?.current?.handleModal();
    }
    try {
      setIsLoading(true);
      const result = await updateStudentUserPassword(
        values.nickName,
        values.password,
        validationToken,
      );
      if (result?.data) {
        toast.success('Conta criada com sucesso!');
        formikRegister.resetForm();
        await delay(1000);
        modalRef?.current?.handleModal();
      }
      navigate(getHref('login'));
    } catch (err: any) {
      const error = err?.response?.data?.error;
      const errors: Record<string, string> = {
        'validationToken are required': 'Token obrigatório.',
        'Invalid or expired validation token': 'Token inválido ou expirado.',
        'Name avatar already exists': 'Nickname já existente, escolha outro.',
      };
      setRegisterErrorMessage(errors[error] || error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (!registerErrorMessage) return;
    setTimeout(() => {
      setRegisterError(false);
      setRegisterErrorMessage(undefined);
    }, 4000);
  }, [registerErrorMessage, registerError]);

  const formik = useFormik({
    initialValues: {
      name: '',
      classCode: '',
    },
    onSubmit: handleCreateAccount,
    validationSchema: validationSchema,
  });

  const formikRegister = useFormik({
    initialValues: {
      nickName: '',
      password: '',
      confirmPassword: '',
    },
    onSubmit: handleRegisterUserInfo,
    validationSchema: validationRegisterSchema,
  });

  return (
    <PageLayout className="mx-0 px-10 py-0 font-[Raleway] md:py-8">
      <div className="mx-0 flex h-full w-full items-center justify-center">
        <div className="flex w-full flex-col items-center justify-center sm:w-[45rem]">
          <img
            className="h-72 w-72 object-contain"
            src={
              origin.includes('neoenergia')
                ? '/img/rockinrio/logo-rock-neo.png'
                : '/svg/cidadeazullogo.svg'
            }
            alt="logo"
          />
          <EmphasisTitle className="mb-8 mt-2 text-secondary">Crie sua conta:</EmphasisTitle>

          <>
            <div className="w-full items-center justify-center">
              <form
                onSubmit={formik.handleSubmit}
                className="flex w-full items-center justify-center"
              >
                <div className="flex w-full flex-col items-center justify-center gap-6">
                  <InputForm
                    className="px-8 text-start"
                    name="name"
                    label="Nome Completo"
                    placeholder="Nome Completo"
                    formik={formik}
                    value={formik.values.name}
                    error={formik.errors.name}
                  />
                  <InputForm
                    className="px-8 text-start"
                    name="classCode"
                    label="Código de Turma"
                    placeholder="Código de Turma"
                    formik={formik}
                    value={formik.values.classCode}
                    error={formik.errors.classCode}
                  />
                  {registerErrorMessage && (
                    <Paragraph className="text-center font-semibold text-[#DE3838]">
                      {registerErrorMessage}
                    </Paragraph>
                  )}
                  <Button
                    className="w-full"
                    type="submit"
                    value={!loading ? 'Criar' : 'Carregando...'}
                    inactive={loading}
                  />
                </div>
              </form>
            </div>
            <SocialLogin setLoginError={setRegisterError} hasAccount />
            <ModalComponent ref={modalRef} width="fit-content" height="fit-content">
              <form
                onSubmit={formikRegister.handleSubmit}
                className="mx-36 my-12 flex w-full items-center justify-center"
              >
                <div className="mx-8 flex w-full flex-col items-center justify-center gap-6">
                  <EmphasisTitle className="my-8 text-secondary">
                    Finalize seu cadastro:
                  </EmphasisTitle>
                  <InputForm
                    className="px-8 text-start"
                    name="nickName"
                    label="Nickname"
                    placeholder="Nickname"
                    formik={formikRegister}
                    value={formikRegister.values.nickName}
                    error={formikRegister.errors.nickName}
                  />
                  <InputForm
                    className="px-8 text-start"
                    name="password"
                    label="Senha"
                    placeholder="Senha"
                    formik={formikRegister}
                    value={formikRegister.values.password}
                    error={formikRegister.errors.password}
                    type="password"
                  />
                  <InputForm
                    className="px-8 text-start"
                    name="confirmPassword"
                    label="confirmPassword"
                    placeholder="Confirme senha"
                    formik={formikRegister}
                    value={formikRegister.values.confirmPassword}
                    error={formikRegister.errors.confirmPassword}
                    type="password"
                  />
                  {registerErrorMessage && (
                    <Paragraph className="mt-4 text-center font-semibold text-[#DE3838]">
                      {registerErrorMessage}
                    </Paragraph>
                  )}
                  <Button
                    className="w-full"
                    type="submit"
                    value={!loading ? 'Confirmar' : 'Carregando...'}
                    inactive={loading}
                  />
                </div>
              </form>
            </ModalComponent>
          </>
        </div>
      </div>
      <ToastContainer stacked style={{ fontSize: '14px' }} toastStyle={{ padding: '20px' }} />
    </PageLayout>
  );
};

export default RegisterStudent;
