interface Cities {
    [key: string]: string[];
}

export const citiesFromClient: Cities = {
    basf: ["guaratingueta"],
    aegea: ["teresina", "sinop", "piracicaba", "cabofrio"],
};

export const companiesByCity: {[key: string]: string} = {
    guaratingueta: "basf",
    teresina: "aegea",
    sinop: "aegea",
    piracicaba: "aegea",
    cabofrio: "aegea",
};

const verifyCityBelongsClient = (client: string | null): boolean => {
    if (!client) return false;
    const cities_for_client = citiesFromClient[client];
    return cities_for_client.includes(localStorage.getItem("city") ?? "");
};

export default verifyCityBelongsClient;
