import { useState } from 'react';
import Button from './Button';

const DeleteConfirm = ({ onClick, messageText, buttonName, buttonProps, icon }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [message, setMessage] = useState('');

  const toggleModal = () => {
    setIsDeleting(false);
    setMessage('');
    setIsModalOpen(!isModalOpen);
  };

  const closeModalOnClickOutside = (e: any) => {
    if (e.target.id === 'deleteModal') {
      toggleModal();
    }
  };

  const onClickButton = async () => {
    setIsDeleting(true);
    const response = await onClick();
    if (response) {
      setMessage('Operação executada com sucesso!');
    } else {
      setMessage('Erro ao resetar senha');
    }
    setIsDeleting(false);
  };

  return (
    <>
      <div>
        <Button onClick={toggleModal} className={buttonProps} value={buttonName} />
      </div>

      {isModalOpen && (
        <div
          id="deleteModal"
          onClick={closeModalOnClickOutside}
          className="fixed left-0 right-0 top-0 z-50 flex h-full items-center justify-center overflow-y-auto overflow-x-hidden bg-black bg-opacity-50"
        >
          <div className="relative w-full max-w-2xl p-8">
            <div
              className="relative rounded-lg p-8 text-center shadow-lg"
              style={{ backgroundColor: 'rgb(45, 79, 109)' }}
            >
              <button
                type="button"
                className="absolute right-3 top-3 inline-flex items-center rounded-lg p-2 text-lg text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={toggleModal}
              >
                <svg
                  aria-hidden="true"
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              {icon}
              <p className="mb-6 text-xl text-gray-200">{messageText}</p>
              <div className="flex items-center justify-center space-x-6">
                <button
                  onClick={toggleModal}
                  className="focus:ring-primary-300 rounded-lg border border-gray-300 bg-white px-5 py-3 text-lg font-medium text-gray-600 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-600"
                >
                  Não, fechar
                </button>
                <button
                  onClick={onClickButton}
                  type="submit"
                  className="rounded-lg bg-red-600 px-5 py-3 text-lg font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
                >
                  Sim, prosseguir
                </button>
              </div>
              {isDeleting && (
                <div className="mt-4">
                  <p className="mb-6 text-xl text-gray-200">Aguarde...</p>
                </div>
              )}
              {message && (
                <div className="mt-6">
                  <p className="mb-6 text-xl text-gray-200">{message}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteConfirm;
